import {combineReducers} from "redux";

import {loginReducer, logoutReducer, facebookLoginReducer} from "./loginReducer";
import {otpRequestReducer, otpVerifyReducer, resetPasswordReducer} from "./resetPasswordReducer"
import {otpRequestRegisterReducer, otpVerifyRegisterReducer, userRegisterReducer} from "./registrationReducer";
import {
    fetchNearMeUsersSettingsReducer,
    getSearchUserReducer,
    getTrendingUserReducer,
    getUserReducer,
    nearMeUsersReducer,
    nearMeUsersSettingsReducer,
    reportUserReducer,
    respondUserReducer
} from "./userReducer"
import {countryReducer} from "./helperReducer"
import {getProfileQuestionsReducer, getProfileReducer, profileReducer} from './profileReducer'
import {changePasswordReducer, requestChangeEmailReducer, requestVerifyEmailReducer} from "./accountSettingsReducer";
import {
    checkFriendStatusReducer,
    getFriendsListReducer,
    listenFriendRequestReducer,
    pendingFriendRequestsReducer,
    respondFriendReducer,
    sentFriendsRequestListReducer
} from "./friendReducer";
import {
    getMembershipPlanDetailsReducer,
    getMembershipPlansReducer,
    purchaseMembershipPlansReducer
} from "./membershipPlansReducer";
import {getMessageUsersReducer, messageReducer} from "./messageReducer";

import {accessFeatureReducer} from "./featureAccessReducer";

import {notificationReducer} from "./notificationReducer";

const rootReducer = combineReducers(
    {
        loginReducer,
        otpRequestReducer,
        otpVerifyReducer,
        otpRequestRegisterReducer,
        otpVerifyRegisterReducer,
        userRegisterReducer,
        resetPasswordReducer,
        getTrendingUserReducer,
        getUserReducer,
        respondUserReducer,
        countryReducer,
        profileReducer,
        getProfileReducer,
        getProfileQuestionsReducer,
        getSearchUserReducer,
        reportUserReducer,
        requestChangeEmailReducer,
        requestVerifyEmailReducer,
        changePasswordReducer,
        logoutReducer,
        getFriendsListReducer,
        pendingFriendRequestsReducer,
        sentFriendsRequestListReducer,
        respondFriendReducer,
        listenFriendRequestReducer,
        getMembershipPlansReducer,
        purchaseMembershipPlansReducer,
        getMembershipPlanDetailsReducer,
        nearMeUsersReducer,
        nearMeUsersSettingsReducer,
        fetchNearMeUsersSettingsReducer,
        accessFeatureReducer,
        checkFriendStatusReducer,
        getMessageUsersReducer,
        messageReducer,
        notificationReducer,
        facebookLoginReducer,
    }
);

export default rootReducer;
