import React, {Component} from 'react';
import {userActions} from "../../actions/userActions";
import {connect} from "react-redux";
import '../../css/style.css'
import {history} from "../app/App";

class UserTrending extends Component {
    componentDidMount() {
        this.props.getTrendingUsers();
    }

    handleImageClick = (userProfileId) => {
        console.log('handleClick userProfileId=', userProfileId);
        history.push(`/users/${userProfileId}`)
    };

    render() {
        const {gettingUsers, users} = this.props;
        console.log('getting users=', gettingUsers);
        console.log('trending users=', users);
        return (
            <div>
                <div className='scrolly'>
                    {
                        users.map((user, i) => (
                            <img onClick={this.handleImageClick.bind(this, user.user_profile_id)} key={i}
                                 className='img-slide' src={user.image.image_250}
                                 alt='user'/>
                        ))
                    }
                </div>
            </div>
        );
    }
}

const mapState = (state) => {
    const {gettingUsers, invalidData, internalError, users, errorMessage} = state.getTrendingUserReducer;
    return {gettingUsers, invalidData, internalError, users, errorMessage};
};

const actionCreators = {
    getTrendingUsers: userActions.getTrendingUsers,
};

export default connect(mapState, actionCreators)(UserTrending);
