import React, {Component} from 'react';
import {loginActions} from "../../actions/loginActions";
import {connect} from "react-redux";
import {history} from "../app/App";
import '../../css/style.css';
import {userConstants} from "../../constants/userConstants";
import Loader from "../Loader/Loader";
import {apiService} from "../../services/apiService";
import {default as LOCALE} from "../../localization";


class Login extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            fieldsEmpty: false,
            fbToken: '',
            accessToken: '',
            permissionDenied: false,
            fbLoginError: false
        };

        this.onInput = this.onInput.bind(this);
    }

    componentDidMount() {
        console.log("public url is ", process.env.PUBLIC_URL)
        console.log("window  url is ", window.location.href)
        if (localStorage.getItem(userConstants.ACCESS_TOKEN)) {
            history.replace('/');
        }
        if (!localStorage.getItem(userConstants.LANGUAGE_DEFAULT)){
            localStorage.setItem(userConstants.LANGUAGE_DEFAULT,"en")
        }

    }

    onLogin = e => {
        e.preventDefault();
        const {username, password} = this.state;

        if (username.length === 0 || password.length === 0) {
            this.setState({fieldsEmpty: true});
            return;
        }
        this.setState({fieldsEmpty: false});

        this.props.login(username, password)
    };

    onInput = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    };

    onResetPassword = e => {
        history.push('/otpRequest')
    };

    onRegister = e => {
        history.push('/otpRequestRegistration')
    };


    responseFacebook = (response) => {
        if (response.status) {
            console.log("response from facebook", response);
            return;
        }
        console.log("response from facebook", response);
        this.props.facebookLogin(response.accessToken);
        this.setState({
            fbToken: response.id,
            accessToken: response.accessToken
        });
    };

    errorFb = () => {

        const {fbToken, accessToken, permissionDenied, fbLoginError} = this.state;
        apiService.invalidateFacebookLogin(fbToken, accessToken).then(response => {
            if (!permissionDenied && !fbLoginError) {
                this.setState({permissionDenied: true})
            }
        }).catch(error => {
            if (!fbLoginError && !permissionDenied) {
                this.setState({fbLoginError: true})
            }

        });
    };

    render() {
        if (!localStorage.getItem(userConstants.LANGUAGE_DEFAULT)){
            localStorage.setItem(userConstants.LANGUAGE_DEFAULT,"en")
        }
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {loggingIn, invalidCredentials, internalError} = this.props.loginReducer;
        const {fbLoggingIn, fbInvalidCredentials, fbInternalError} = this.props.facebookLoginReducer;
        const {username, password, fieldsEmpty, permissionDenied, fbLoginError} = this.state;
        if (fbInvalidCredentials) {
            this.errorFb()
        }
        console.log('language default is ',localStorage.getItem(userConstants.LANGUAGE_DEFAULT));

        return (
            <div className="login-page">
                <div className="login-form">
                    <form onSubmit={this.onLogin}>
                        <h1 className="login-page-title">{LOCALE.login}</h1>
                        {fieldsEmpty && <div className="user-pw">{LOCALE.enter_both_name_and_password}</div>}
                        {invalidCredentials && <div className="user-pw">{LOCALE.invalid_credentials}</div>}
                        {fbLoginError && <div className="user-pw">{LOCALE.something_went_wrong}</div>}
                        {internalError && <div className="user-pw">{LOCALE.internal_error}</div>}
                        {fbInternalError && <div className="user-pw">{LOCALE.internal_error}</div>}
                        <input
                            className={invalidCredentials || fieldsEmpty || internalError ? "input-field input-field-error" : "input-field"}
                            placeholder={LOCALE.username}
                            disabled={loggingIn}
                            type='text'
                            name='username'
                            value={username}
                            onChange={this.onInput}
                        />

                        <br/>
                        <input
                            className={invalidCredentials || fieldsEmpty || internalError ? "input-field input-field-error" : "input-field"}
                            placeholder={LOCALE.password}
                            disabled={loggingIn}
                            type='password'
                            name='password'
                            value={password}
                            onChange={this.onInput}
                        />
                        <br/>
                        <button className={loggingIn ? "btn1 login-btn-disable" : "btn1"} disabled={loggingIn}
                                type='submit'>{loggingIn ? "" : LOCALE.login}</button>
                        {loggingIn && <div className="lds-dual-ring"/>}
                    </form>
                    {/*                    <div>OR</div>
                    <br/>
                    <FacebookLogin
                        appId={process.env.REACT_APP_FACEBOOK_APP_ID} //APP ID NOT CREATED YET
                        scope="public_profile"
                        callback={this.responseFacebook}
                        redirectUri={window.location.href}
                    />*/}
                    <p>{LOCALE.donot_have_account}
                        <button className="btn-clear" disabled={loggingIn}
                                onClick={this.onRegister}><strong>{LOCALE.register}</strong></button>
                    </p>
                    {fbLoggingIn && <Loader/>}
                    {permissionDenied &&
                    <div className="user-pw">{LOCALE.allow_fb_permissions}</div>}


                    <button className="btn-clear forget-pw" disabled={loggingIn}
                            onClick={this.onResetPassword}>{LOCALE.forgot_password}
                    </button>
                </div>
            </div>
        )
    }
}


const mapState = (state) => {
    return {
        loginReducer: state.loginReducer,
        facebookLoginReducer: state.facebookLoginReducer
    };
};

const actionCreators = {
    login: loginActions.login,
    facebookLogin: loginActions.facebookLogin
};

export default connect(mapState, actionCreators)(Login);
