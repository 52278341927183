import React, {Component} from 'react';
import {Link} from "react-router-dom";
import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import {default as LOCALE} from "../../localization";
import {userConstants} from "../../constants/userConstants";

class Tab extends Component {

    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        return (
            <nav className="navbar navbar-light">
                <div className="container">
                    <ul className="nav navbar-nav pull-xs-right">


                        <li className="nav-item">
                            <Link to="/" className="nav-link" replace={true}>
                                <i className="ion-compose">{LOCALE.home}</i>
                            </Link>
                        </li>

                        <li className="nav-item">
                            <Link to="/message" className="nav-link" replace={true}>
                                <i className="ion-gear-a">{LOCALE.message}</i>
                            </Link>
                        </li>

                        <li className="nav-item advance-search-nav">
                            <Link to="/search" className="nav-link" replace={true}>
                                <i className="ion-compose">{LOCALE.search}</i>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <DrawerToggleButton click={this.props.drawerClickHandler} />
                            {/*<Link to="/users/me" className="nav-link" replace={false}>*/}
                            {/*    <i className="ion-gear-a">Profile</i>*/}
                            {/*</Link>*/}
                        </li>

                    </ul>
                </div>
            </nav>
        );
    }
}

export default Tab;
