import React, {Component} from 'react';
import {friendActions} from "../../actions/friendActions";
import {connect} from "react-redux";
import {history} from "../app/App";
import {utils} from "../../helper/utils";
import Loader from "../Loader/Loader";
import {default as LOCALE} from "../../localization";
import {userConstants} from "../../constants/userConstants";

class ViewFriends extends Component {
    componentDidMount() {
        this.props.getFriendsList();

    }

    handleImageClick = (userProfileId) => {
        history.push(`/users/${userProfileId}`)
    };
    paginateIfNecessary = (e) => {
        const {gettingFriends, lastPage, page} = this.props.getFriendsListReducer;
        console.log('paginateIfNecessary', {
            scrollHeight: e.target.scrollHeight,
            scrollTop: e.target.scrollTop,
            clientHeight: e.target.clientHeight
        });
        const bottom = Math.round(e.target.scrollHeight) - Math.round(e.target.scrollTop) === Math.round(e.target.clientHeight);

        console.log('paginateIfNecessary bottom', bottom);

        if (bottom) {
            if (lastPage || gettingFriends) {
                gettingFriends ? console.log('getting search users already') : console.log('last page reached');
            } else {
                console.log('getting more users', page);
                this.props.getFriendsList(page + 1);
            }
        }
    };


    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {gettingFriends, friends, invalidData, internalError, errorMessage} = this.props.getFriendsListReducer;
        console.log('searchResults render users=', friends);
      if (friends.length > 0) {
            return (
                <div className='home-page-wrapper search-result'>
                    <div className="home-page">
                        <div className="top-bar">
                            <img onClick={() => {
                                history.goBack();
                            }} src={require('../../img/back.png')} alt="back-button"/>
                            <h3>{LOCALE.all_friends}</h3>
                        </div>

                        <div className='scrollx' onScroll={this.paginateIfNecessary}>
                            {
                                friends.map((friend, i) => (
                                    <div onClick={this.handleImageClick.bind(this, friend.user_profile_id)} key={i}>
                                        <div className="searched-image">
                                            <div className="searched-img">
                                                <img
                                                    key={i}
                                                    className='img-search' src={friend.image.image_250}
                                                    alt='user'/>
                                            </div>
                                            <div className="searched-det">
                                                <label>{`${friend.display_name}, ${utils.getAge(friend.age)}`}</label>
                                                <br/>
                                                <label>{friend.countryName}, </label>
                                                {friend.stateName !== 'None' ? <label>{friend.stateName}, </label> : ''}
                                                <label>{friend.cityName}</label>
                                            </div>
                                        </div>
                                    </div>
                                ))

                            }
                        </div>
                        {gettingFriends && <Loader/>}


                        {/*<div>Back to Profile?*/}
                        {/*    <button className="btn-clear top-space"*/}
                        {/*            onClick={() => {*/}
                        {/*                history.goBack();*/}
                        {/*            }}><strong>Click here</strong>*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                    </div>
                </div>
            );

        }
        if (gettingFriends) {
            return (
                <div className="home-page-wrapper">
                    <div className="home-page">
                        <div className="top-bar">
                            <img onClick={() => {
                                history.goBack();
                            }} src={require('../../img/back.png')} alt="back-button"/>
                            <h3>{LOCALE.all_friends}</h3>
                        </div>
                        <Loader/>
                    </div>
                </div>
            );
        }
      else {
            return (
                <div className="home-page-wrapper friends-list">
                    <div className="home-page">
                        <div className="top-bar">
                            <img onClick={() => {
                                history.goBack();
                            }} src={require('../../img/back.png')} alt="back-button"/>
                            <h3>{LOCALE.all_friends}</h3>
                        </div>
                        <div className="homepage-content">
                            <h2>{LOCALE.no_friends_yet}</h2>
                            {gettingFriends && <Loader/>}
                            {internalError && <div>{LOCALE.internal_error}</div>}
                            {invalidData && <div>{errorMessage}</div>}
                            <button className="btn00" onClick={() => {
                                history.push('/search')
                            }}> {LOCALE.searchFriends}
                            </button>
                            {/*
                            <div>Back to Profile?
                                <button className="btn-clear top-space"
                                        onClick={() => {
                                            history.goBack();
                                        }}><strong>Click here</strong>
                                </button>
                            </div>*/}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapState = (state) => {
    return {
        getFriendsListReducer: state.getFriendsListReducer
    }
};
const actionCreators = {
    getFriendsList: friendActions.getFriendsList
};
export default connect(mapState, actionCreators)(ViewFriends);
