import React, {Component} from 'react';
import {profileActions} from "../../actions/profileActions";
import {connect} from "react-redux";
import {utils} from "../../helper/utils";
import {genderConstants} from "../../constants/genderConstants";
import {history} from "../app/App";
import '../../css/style.css'
import {friendActions} from "../../actions/friendActions";
import RespondFriendButton from "../friends/respondFriendButton";
import Loader from "../Loader/Loader";
import {default as LOCALE} from "../../localization";
import {userConstants} from "../../constants/userConstants";

class ViewProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showFullDetails: false,
            isLoggedFacebook: false,

        };
    }

    state = {
        on: false,
    };

    toggle = () => {
        this.setState({
            on: !this.state.on
        })
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevUserId = prevProps.match.params.userId;
        const userId = this.props.match.params.userId;

        if (prevUserId !== userId) {
            this.props.getProfile(userId);
        }
    }

    componentDidMount() {
        console.log('componentDidMount');
        const userId = this.props.match.params.userId;
        this.props.getProfile(userId);
    }

    onShowFullDetailToggled = () => {
        this.setState({showFullDetails: !this.state.showFullDetails})
    };

    getUserGender = (gender) => {
        switch (gender) {
            case genderConstants.GENDER_TYPE_MALE:
                return genderConstants.GENDER_MALE;

            case genderConstants.GENDER_TYPE_FEMALE:
                return genderConstants.GENDER_FEMALE;

            case genderConstants.GENDER_TYPE_COUPLES:
                return genderConstants.GENDER_COUPLES;
            default:
                return;
        }
    };

    combineProfileQuestions = (profileQuestions) => {
        const nameValue = {};
        for (let i = 0; i < profileQuestions.length; i++) {
            const profileQuestionName = profileQuestions[i].profile_question_name;
            const optionValue = profileQuestions[i].option_value;

            if (nameValue[profileQuestionName]) {
                let optionValues = nameValue[profileQuestionName];
                optionValues += ', ' + (optionValue);
                nameValue[profileQuestionName] = optionValues;
            } else {
                nameValue[profileQuestionName] = optionValue;
            }
        }

        const list = [];

        for (const name in nameValue) {
            if (nameValue.hasOwnProperty(name)) {
                const item = {name: name, values: nameValue[name]};
                list.push(item);
            }
        }

        return list;
    };

    isMyProfile = () => {
        const userId = this.props.match.params.userId;
        return userId === 'me';
    };

    openEditProfile = () => {
        history.push('/updateProfile', {createNew: false, initialData: this.responseData});
    };

    openEditProfileQuestion = () => {
        history.push('/profileQuestions', {initialData: this.responseData.profile_questions});
    };

    getDiv = (data) => {
        const {showFullDetails} = this.state;

        this.responseData = {
            ...data,
            profile_questions_combined: this.combineProfileQuestions(data.profile_questions)
        };

        const {
            id,
            image,
            display_name,
            age,
            countryName,
            stateName,
            cityName,
            gender,
            seeking,
            about_me,
            my_interest,
            profile_questions_combined,
        } = this.responseData;

        return <div>

            <div className="image-wrapper"><img className='crop-area2' src={image.image_orig}
                                                alt="user"/>
            </div>

            <div className="profile-view-content">

                <div className="profile-view-name-wrapper"><label
                    className="profile-view-name">{display_name}, {utils.getAge(age)}</label>
                    <label className="profile-view-gender"><strong>{this.getUserGender(gender)}</strong></label>
                </div>

                <div className="profile-view-country">
                    <label>{LOCALE.seeking_}<span>: </span> <strong>{this.getUserGender(seeking)}</strong></label>
                    <label>{LOCALE.from}<span>: </span><strong>{countryName}</strong> </label>
                </div>

                {!this.isMyProfile() && <RespondFriendButton friendId={id} responseData={this.responseData}/>
                }
                {/*{stateName !== 'None' ? <label>{stateName}, </label> : ''}*/}
                {/*<label>{cityName}</label>*/}

                <label className="profile-view-about"> {about_me}</label>
            </div>

            <div className="basic-details">
                <div className="details-section">
                    <label>{LOCALE.i_am} </label>
                    <label>{LOCALE.seeking_a} </label>
                    <label>{LOCALE.ages}</label>
                    <label>{LOCALE.profile_country}</label>
                    {stateName !== 'None' ? <div><label>{LOCALE.profile_state}</label></div> : ''}
                    <label>{LOCALE.profile_city}</label>
                    <label>{LOCALE.my_interests}</label>
                </div>
                <div className="details-section">
                    <label> {this.getUserGender(gender)}</label>
                    <label> {this.getUserGender(seeking)}</label>
                    <label> {utils.getAge(age)}</label>
                    <label> {countryName}</label>
                    {stateName !== 'None' ? <div><label> {stateName}</label></div> : ''}
                    <label> {cityName}</label>
                    <label> {my_interest}</label>
                </div>
                {<button className="btn-clear"
                         onClick={this.onShowFullDetailToggled}> {!showFullDetails ? LOCALE.show_full_details : LOCALE.hide_full_details}</button>}
            </div>

            {showFullDetails &&
            <div className="basic-details2">
                {profile_questions_combined.map((item, i) =>
                    <div className="basic-details-inner" key={i}>
                        <div className="details-section">
                            <label>{item.name} :</label>
                        </div>
                        <div className="details-section">
                            <label> {item.values}</label>
                        </div>
                    </div>
                )}
                {profile_questions_combined.length === 0 && <div>
                    {LOCALE.no_profile_available}</div>}
                {this.isMyProfile() &&
                <button className='btn01' onClick={this.openEditProfileQuestion}>{LOCALE.edit_profile_questions}</button>
                }
            </div>}

        </div>
    };

    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {gettingProfile, responseData, internalError, invalidData, errorMessage} = this.props.getProfileReducer;
        return (
            <div className="home-page-wrapper">
                <div className='home-page'>
                    <div className="top-bar">
                        <img onClick={() => {
                            history.goBack();
                        }} src={require('../../img/back.png')} alt="back-button"/>
                        <h3>{LOCALE.profile}</h3>
                        <div className="right-nav" onClick={this.toggle}>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>

                        {this.state.on &&
                        <div className="basic-action">
                            {this.isMyProfile() && <div>
                                <div className="div-show" onClick={() => {
                                    history.push("/viewFriendsList", {initialData: this.responseData})
                                }}>
                                    <button className="btn-clear">{LOCALE.all_friends}
                                    </button>
                                </div>
                                <div className="div-show" onClick={() => {
                                    history.push("/viewSentFriendRequests")
                                }}>
                                    <button className="btn-clear">{LOCALE.pending_requests}
                                    </button>
                                </div>
                                <div className="div-show" onClick={() => {
                                    history.push("/pendingFriendRequests")
                                }}>
                                    <button className="btn-clear">{LOCALE.friend_requests}
                                    </button>
                                </div>
                            </div>
                            }
                            {!this.isMyProfile() &&
                            <div className="div-show" onClick={() => {
                                history.push("/reportUser", {initialData: this.responseData})
                            }}>
                                <button className="btn-clear">{LOCALE.report_user}
                                </button>
                            </div>
                            }

                        </div>
                        }

                    </div>

                    <div className="image-wrapper2">{responseData && this.getDiv(responseData)}</div>


                    {gettingProfile && <Loader/>}
                    {internalError && <div>{LOCALE.internal_error}</div>}
                    {invalidData && <div>{errorMessage}</div>}


                    {this.isMyProfile() && !gettingProfile && this.responseData && <div className="view-profile-footer">
                        <button className='btn01' onClick={this.openEditProfile}>{LOCALE.edit_profile}</button>
                        {responseData && responseData.logged_in_from !== "facebook" &&
                        <button className="btn01" onClick={() => {
                            history.push("/accountSettings", {initialData: this.responseData})
                        }}>{LOCALE.account_settings}
                        </button>
                        }
                    </div>
                    }
                </div>
            </div>
        );
    }
}

const actionCreators = {
    getProfile: profileActions.getProfile,
    sendFriendRequest: friendActions.sendFriendRequest
};

const mapState = (state) => {
    return {
        getProfileReducer: state.getProfileReducer,
        sendFriendRequestReducer: state.sendFriendRequestReducer
    }
};
export default connect(mapState, actionCreators)(ViewProfile);
