import {profileConstants} from "../constants/profileConstants";

const initialState = {
    profileState: {
        updatingProfile: false,
        invalidData: false,
        internalError: false,
        responseData: {},
        errorMessage: ''
    },
    getProfileState: {
        gettingProfile: false,
        invalidData: false,
        internalError: false,
        responseData: null,
        errorMessage: ''
    },
    getProfileQuestions: {
        gettingProfileQuestions: false,
        invalidData: false,
        internalError: false,
        responseData: null,
        errorMessage: ''
    },
};

const profileReducer = (state = initialState.profileState, action) => {
    switch (action.type) {
        case profileConstants.UPDATE_PROFILE_REQUEST:
            return {
                ...initialState.profileState,
                updatingProfile: true
            };
        case profileConstants.UPDATE_PROFILE_SUCCESS:
            return {
                ...initialState.profileState,
                responseData: action.responseData
            };
        case profileConstants.UPDATE_PROFILE_FAILURE:
            switch (action.errorCode) {
                case 400:
                case 401:
                case 404:
                    return {
                        ...initialState.profileState,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.profileState,
                        internalError: true
                    }
            }
        default:
            return state;
    }
};

const getProfileReducer = (state = initialState.getProfileState, action) => {

    switch (action.type) {
        case profileConstants.VIEW_PROFILE_REQUEST:
            return {
                ...initialState.getProfileState,
                gettingProfile: true
            };
        case profileConstants.VIEW_PROFILE_SUCCESS:
            return {
                ...initialState.getProfileState,
                responseData: action.responseData
            };
        case profileConstants.VIEW_PROFILE_FAILURE:
            switch (action.errorCode) {
                case 400:
                case 401:
                case 404:
                    return {
                        ...initialState.getProfileState,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.getProfileState,
                        internalError: true
                    }
            }
        default:
            return state;
    }
};

const getProfileQuestionsReducer = (state = initialState.getProfileQuestions, action) => {

    switch (action.type) {
        case profileConstants.GET_PROFILE_QUESTION_REQUEST:
            return {
                ...initialState.getProfileQuestions,
                gettingProfileQuestions: true
            };
        case profileConstants.GET_PROFILE_QUESTION_SUCCESS:
            return {
                ...initialState.getProfileQuestions,
                responseData: action.responseData
            };
        case profileConstants.GET_PROFILE_QUESTION_FAILURE:
            switch (action.errorCode) {
                case 400:
                case 401:
                case 404:
                    return {
                        ...initialState.getProfileQuestions,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.getProfileQuestions,
                        internalError: true
                    }
            }
        default:
            return state;
    }
};

export {profileReducer, getProfileReducer, getProfileQuestionsReducer};
