import React, {Component} from 'react';
import {history} from "../app/App";
import {default as LOCALE} from "../../localization";
import {userConstants} from "../../constants/userConstants";

class AccountSettings extends Component {
    constructor(props) {
        super(props);
        const {initialData} = this.props.location.state;


        this.state = {

            email: initialData.email,
            password: '**********'
        }
    }

    onInput = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    };

    changeEmail = () => {
        history.push("/changeEmail")
    };

    changePassword = () => {
        history.push("/changePassword")

    };

    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {email, password} = this.state;

        return (
            <div className='home-page-wrapper account-settings'>
                <div className="home-page">
                    <div className="top-bar">
                        <img onClick={() => {
                            history.goBack();
                        }} src={require('../../img/back.png')} alt="back-button"/>
                        <h3>{LOCALE.account_settings}</h3>
                    </div>
                    <div className="homepage-content">
                        <br/>
                        <div className="account-set">
                            <div className="account-details">{email}</div>
                            <button className="btn-clear" onClick={this.changeEmail}>{LOCALE.update_email}</button>
                        </div>
                        <br/>
                        <div className="account-set">
                            <div className="account-details">{password}</div>
                            <button className="btn-clear" onClick={this.changePassword}>{LOCALE.change_password}</button>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}

export default AccountSettings;
