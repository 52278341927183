import axios from "axios";
import {userConstants} from "../constants/userConstants";
import {friendsConstants} from "../constants/friendsConstants";

export const apiService = {
    login,
    logout,
    sendOTP,
    verifyOTP,
    sendOTPRegistration,
    verifyOTPRegistration,
    userRegistration,
    resetPassword,
    getUsers,
    respondUser,
    getTrendingUsers,
    updateProfile,
    getMyProfile,
    getProfile,
    getProfileQuestions,
    updateProfileQuestions,
    getSearchUsers,
    reportUser,
    changeEmail,
    verifyEmail,
    changePassword,
    getFriendsList,
    sentFriendsRequestList,
    pendingFriendRequests,
    respondFriend,
    getMembershipPlansList,
    purchaseMembershipPlan,
    getMembershipPlanDetails,
    getNearMe,
    updateNearMeSettings,
    getNearMeSettings,
    getMessageUsers,
    getMessages,
    sendMessage,
    checkFriendStatus,
    checkFeatureAccess,
    loginWithFacebook,
    invalidateFacebookLogin,
    checkVersion
};

const base_url = process.env.REACT_APP_BASE_URL;
const client_id = process.env.REACT_APP_CLIENT_ID;
const client_secret = process.env.REACT_APP_CLIENT_SECRET;

function login(username, password) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
        }
    };
    const body = {
        grant_type: 'password',
        username: username,
        password: password,
        client_id: client_id,
        client_secret: client_secret,
    };

    const request = axios.post(`${base_url}/oauth/token`, body, config);

    console.log(request);

    return request;
}


function sendOTP(username) {
    const config = {
        headers: {

            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
        }
    };
    const body = {
        username: username
    };

    const request = axios.post(`${base_url}/wp-json/wpdating/v1/password/forgot`, body, config);

    return request;

}

function verifyOTP(otpCode, insertId) {
    const config = {
        headers: {

            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
        }
    };
    const body = {
        code: otpCode
    };

    const request = axios.post(`${base_url}/wp-json/wpdating/v1/password/forgot/${insertId}`, body, config);
    return request

}

function resetPassword(newPassword, userId) {
    const config = {
        headers: {

            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
        }
    };

    const body = {
        password: newPassword

    };

    const request = axios.post(`${base_url}/wp-json/wpdating/v1/password/reset/${userId}`, body, config);
    return request

}


function sendOTPRegistration(userEmail) {
    const config = {
        headers: {

            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
        }
    };
    const body = {
        email: userEmail
    };

    const request = axios.post(`${base_url}/wp-json/wpdating/v1/verification`, body, config);

    return request;

}

function verifyOTPRegistration(otpCode, insertId) {
    const config = {
        headers: {

            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
        }
    };
    const body = {
        code: otpCode
    };

    const request = axios.post(`${base_url}/wp-json/wpdating/v1/verification/${insertId}`, body, config);
    return request

}


function userRegistration(params) {
    const config = {
        headers: {

            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
        }
    };

    const request = axios.post(`${base_url}/wp-json/wpdating/v1/users`, params, config);

    console.log('userRegistration request=', request);

    return request

}

function getUsers(page = 1) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    const request = axios.get(`${base_url}/wp-json/wpdating/v1/extra/meet-me?page=${page}`, config);

    return request;
}

function respondUser(userId) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    const body = {
        user_id: userId
    };

    const request = axios.post(`${base_url}/wp-json/wpdating/v1/extra/meet-me`, body, config);

    console.log('respondUser request=', request);

    return request;
}


function getTrendingUsers() {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    const request = axios.get(`${base_url}/wp-json/wpdating/v1/extra/trending`, config);

    return request;
}

function updateProfile(image, gender, age, seeking, countryId, stateId, cityId, aboutMe, myInterest, createNew = false) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

    console.log('updateProfile body=', {
        image,
        gender,
        age,
        seeking,
        countryId,
        stateId,
        cityId,
        aboutMe,
        myInterest,
        createNew
    });

    const body = new FormData();

    if (createNew) {
        body.append('image_orig', image.image_orig, 'image_orig.jpeg');
        body.append('image_350', image.image_350, 'image_350.jpeg');
        body.append('image_250', image.image_250, 'image_250.jpeg');
        body.append('seeking', seeking);
        body.append('gender', gender);
        body.append('age', age);
        body.append('country_id', countryId);
        body.append('state_id', stateId);
        body.append('city_id', cityId);
        body.append('about_me', aboutMe);
        body.append('my_interest', myInterest);
    } else {
        if (image) {
            body.append('image_orig', image.image_orig, 'image_orig.jpeg');
            body.append('image_350', image.image_350, 'image_350.jpeg');
            body.append('image_250', image.image_250, 'image_250.jpeg');
        }
        if (seeking) {
            body.append('seeking', seeking);
        }
        if (gender) {
            body.append('gender', gender);
        }
        if (age) {
            body.append('age', age);
        }
        if (countryId) {
            body.append('country_id', countryId);
        }
        if (stateId === 0 || stateId) {
            body.append('state_id', stateId);
        }
        if (cityId) {
            body.append('city_id', cityId);
        }
        if (aboutMe) {
            body.append('about_me', aboutMe);
        }
        if (myInterest) {
            body.append('my_interest', myInterest);
        }
    }

    const config = {
        headers: {
            'Content-Type': `multipart/form-data; boundary=${body._boundary}`,
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    let request;
    if (createNew) {
        request = axios.post(`${base_url}/wp-json/wpdating/v1/users/profile/create`, body, config);
    } else {
        request = axios.post(`${base_url}/wp-json/wpdating/v1/users/profile/me`, body, config);
    }

    console.log('updateProfile request=', request);

    return request;
}

function getMyProfile() {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    const request = axios.get(`${base_url}/wp-json/wpdating/v1/users/profile/me`, config);

    return request;
}


function getProfile(userId) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    let request = axios.get(`${base_url}/wp-json/wpdating/v1/users/profile/${userId}`, config);

    console.log('getProfile request=', request);

    return request;
}

async function getProfileQuestions() {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };
    const request = await axios.get(`${base_url}/wp-json/wpdating/v1/users/profile/profile-questions`, config);
    console.log('getProfileQuestions request=', request);
    return request;
}

function updateProfileQuestions(profileQuestions) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
    /*

        profileQuestions = [{
            "field_type_id": "1",
            "profile_setup_id": "16",
            "question_option_id": "0",
            "option_value": ""
        }];
    */

    const body = {
        profile_questions: JSON.stringify(profileQuestions)
    };

    console.log('updateProfileQuestions body=', body);

    const config = {
        headers: {
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    const request = axios.post(`${base_url}/wp-json/wpdating/v1/users/profile/me`, body, config);

    console.log('updateProfileQuestions request=', request);

    return request;

}


function getSearchUsers(searchParams, searchType, page = 1) {
    let answers = [];
    for (const key in searchParams.additional_options) {
        if (searchParams.additional_options.hasOwnProperty(key)) {
            const {answer} = searchParams.additional_options[key];
            for (const question_id of answer.options) {
                answers.push(question_id.question_option_id);
            }
        }
    }

    searchParams = {
        ...searchParams,
        country_id: searchParams.country_id.toString(),
        state_id: searchParams.state_id.toString(),
        city_id: searchParams.city_id.toString(),
        additional_options: "[" + answers.toString() + "]"
    };

    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
    console.log("those are ", searchParams, searchType);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };
    let request = axios.post(`${base_url}/wp-json/wpdating/v1/search?search=${searchType}&page=${page}`, searchParams, config);

    console.log('getSearchUsers request=', searchParams);

    return request;
}


function reportUser(reportMessage, userId) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    const body = {
        reason: reportMessage
    };

    const request = axios.post(`${base_url}/wp-json/wpdating/v1/report-user/${userId}`, body, config);

    return request;
}


function changeEmail(userEmail) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

    const config = {
        headers: {

            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`

        }
    };
    const body = {
        email: userEmail
    };

    const request = axios.post(`${base_url}/wp-json/wpdating/v1/accountsetting/verification`, body, config);

    return request;

}


function verifyEmail(insertId, code) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

    const config = {
        headers: {

            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`

        }
    };
    const body = {
        code: code
    };

    const request = axios.post(`${base_url}/wp-json/wpdating/v1/accountsetting/verification/${insertId}`, body, config);

    return request;

}

function changePassword(oldPassword, newPassword) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

    const config = {
        headers: {

            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`

        }
    };
    const body = {
        old_password: oldPassword,
        new_password: newPassword
    };

    const request = axios.post(`${base_url}/wp-json/wpdating/v1/password/change`, body, config);

    return request;

}

function logout() {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
        }
    };
    let request = axios.post(`${base_url}/oauth/destroy?access_token=${accessToken}`, config);


    return request;
}

function getFriendsList(page = 1) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };
    let request = axios.get(`${base_url}/wp-json/wpdating/v1/friends/me?page=${page}`, config);

    console.log('getFriendList request=', request);

    return request;
}

function sentFriendsRequestList(page = 1) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };
    let request = axios.get(`${base_url}/wp-json/wpdating/v1/friends/outgoing?page=${page}`, config);

    console.log('getFriendList request=', request);

    return request;
}

function pendingFriendRequests(page = 1) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };
    let request = axios.get(`${base_url}/wp-json/wpdating/v1/friends/incoming/?page=${page}`, config);

    console.log('getFriendList request=', request);

    return request;
}

function respondFriend(id, operation) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    let body;
    let request;
    switch (operation) {
        case friendsConstants.FRIEND_SEND_REQUEST:
            body = {
                friend_uid: id
            };
            request = axios.post(`${base_url}/wp-json/wpdating/v1/friends`, body, config);
            break;
        case friendsConstants.FRIEND_CANCEL_REQUEST:
            request = axios.delete(`${base_url}/wp-json/wpdating/v1/friends/cancel/${id}`, config);
            break;
        case friendsConstants.FRIEND_ACCEPT_REQUEST:
            body = {
                friend_request_id: id,
                status: 'accept'
            };
            request = axios.post(`${base_url}/wp-json/wpdating/v1/friends/accept`, body, config);
            break;
        case friendsConstants.FRIEND_DECLINE_REQUEST:
            body = {
                friend_request_id: id,
                status: 'reject'
            };
            request = axios.post(`${base_url}/wp-json/wpdating/v1/friends/accept`, body, config);
            break;
        case friendsConstants.FRIEND_UNFRIEND:
            request = axios.delete(`${base_url}/wp-json/wpdating/v1/friends/${id}`, config);
            break;
        default:
            break;
    }

    console.log('respondFriend().operation=', operation);
    console.log('addFriendRequest request=', request);

    return request;
}

function getMembershipPlansList() {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    let request = axios.get(`${base_url}/wp-json/wpdating/v1/membership/list`, config);

    console.log('getmembershipplans request=', request);

    return request;
}

async function purchaseMembershipPlan(token, product) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    let request = await axios.post(`${base_url}/wp-json/wpdating/v1/payment/checkout/stripe`, {token, product}, config);

    console.log('getmembershipplans request=', request);

    return request;
}

function getMembershipPlanDetails(membershipId) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    let request = axios.get(`${base_url}/wp-json/wpdating/v1/membership/${membershipId}`, config);

    return request;
}

function getNearMe(lat, lng, page) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

    const body = {
        lat: lat,
        lng: lng
    };
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    let request = axios.post(`${base_url}/wp-json/wpdating/v1/near-me/?page=${page}`, body, config);

    return request;
}

function updateNearMeSettings(unit, value) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

    const body = {
        unit: unit,
        value: value
    };
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    let request = axios.post(`${base_url}/wp-json/wpdating/v1/set/near-me`, body, config);

    return request;
}

function getNearMeSettings() {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);


    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    let request = axios.get(`${base_url}/wp-json/wpdating/v1/near-me`, config);

    return request;
}

function checkFriendStatus(friend_uid) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);


    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    let request = axios.get(`${base_url}/wp-json/wpdating/v1/friends/check/${friend_uid}`, config);

    return request;
}


function checkFeatureAccess(feature) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);

    const body = {
        feature: feature,
    };
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    let request = axios.post(`${base_url}/wp-json/wpdating/v1/feature_access`, body, config);

    return request;
}

function getMessageUsers(page = 1) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    const request = axios.get(`${base_url}/wp-json/wpdating/v1/one-to-one-chat/users?page=${page}`, config);

    return request;
}

function getMessages(userId, createdAt = '') {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    const request = axios.get(`${base_url}/wp-json/wpdating/v1/one-to-one-chat/${userId}?created_at=${createdAt}`, config);

    return request;
}

function sendMessage(userId, message, deviceMessageId) {
    const accessToken = localStorage.getItem(userConstants.ACCESS_TOKEN);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    const body = {
        device_message_id: deviceMessageId,
        chat_text: message
    };

    const request = axios.post(`${base_url}/wp-json/wpdating/v1/one-to-one-chat/${userId}`, body, config);

    return request;
}

function loginWithFacebook(fbToken) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
        }
    };

    const body = {
        fb_token: fbToken,
    };

    const request = axios.post(`${base_url}/wp-json/wpdating/v1/facebook/login`, body, config);

    return request;
}

function invalidateFacebookLogin(fbToken, accessToken) {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${accessToken}`
        }
    };

    const request = axios.delete(`https://graph.facebook.com/v5.0/${fbToken}/permissions`, config);

    return request;
}

function checkVersion() {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            "Access-Control-Allow-Origin": "*",
        }
    };

    const request = axios.get(`${base_url}/wp-json/wpdating/v1/app/version`, config);

    return request;
}

