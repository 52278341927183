import {apiService} from "../services/apiService";
import {utils} from "../helper/utils";
import {friendsConstants} from "../constants/friendsConstants";
import firebase from "firebase";
import 'firebase/database'

export const friendActions = {
    getFriendsList,
    sentFriendsRequestList,
    pendingFriendsRequestList,
    respondFriend,
    listenFriendRequest,
    checkFriendStatus
};

function getFriendsList(page = 1) {
    return dispatch => {
        dispatch(request(page === 1));
        apiService.getFriendsList(page)
            .then(response => {
                console.log('getFriends response=', page);
                utils.addCountryStateCityNameToUsers(response.data.data, (users) => {
                    response.data.data = users;
                    dispatch(success(response.data, page === 1));
                });
            })
            .catch(error => {
                if (error && error.response && error.response.status && error.response.data.message) {
                    dispatch(failure(error.response.status, error.response.data.message))
                } else {
                    dispatch(failure(-1));
                }
            });
    };

    function request(reset) {
        return {
            type: friendsConstants.FETCH_FRIENDS_LISTS_REQUEST,
            reset
        };
    }

    function success(responseData, reset) {
        return {
            type: friendsConstants.FETCH_FRIENDS_LISTS_SUCCESS,
            responseData: responseData,
            reset
        }
    }

    function failure(errorCode, errorMessage) {
        return {
            type: friendsConstants.FETCH_FRIENDS_LISTS_FAILURE,
            errorCode: errorCode,
            errorMessage: errorMessage
        };
    }
}

function sentFriendsRequestList(page = 1) {
    return dispatch => {
        dispatch(request(page === 1));
        apiService.sentFriendsRequestList(page)
            .then(response => {
                console.log('get sent friend request response=', page);
                utils.addCountryStateCityNameToUsers(response.data.data, (users) => {
                    response.data.data = users;
                    dispatch(success(response.data, page === 1));
                });
            })
            .catch(error => {
                if (error && error.response && error.response.status && error.response.data.message) {
                    dispatch(failure(error.response.status, error.response.data.message))
                } else {
                    dispatch(failure(-1));
                }
            });
    };

    function request(reset) {
        return {
            type: friendsConstants.FETCH_FRIENDS_REQUEST_LISTS_REQUEST,
            reset
        };
    }

    function success(responseData, reset) {
        return {
            type: friendsConstants.FETCH_FRIENDS_REQUEST_LISTS_SUCCESS,
            responseData: responseData,
            reset
        }
    }

    function failure(errorCode, errorMessage) {
        return {
            type: friendsConstants.FETCH_FRIENDS_REQUEST_LISTS_FAILURE,
            errorCode: errorCode,
            errorMessage: errorMessage
        };
    }
}

function pendingFriendsRequestList(page = 1) {
    return dispatch => {
        dispatch(request());
        apiService.pendingFriendRequests(page)
            .then(response => {
                console.log('pending friend request response=', page);
                utils.addCountryStateCityNameToUsers(response.data.data, (users) => {
                    response.data.data = users;
                    dispatch(success(response.data, page === 1));
                });
            })
            .catch(error => {
                if (error && error.response && error.response.status && error.response.data.message) {
                    dispatch(failure(error.response.status, error.response.data.message))
                } else {
                    dispatch(failure(-1));
                }
            });
    };

    function request(reset) {
        return {
            type: friendsConstants.FETCH_PENDING_FRIEND_LISTS_REQUEST,
            reset
        };
    }

    function success(responseData, reset) {
        return {
            type: friendsConstants.FETCH_PENDING_FRIEND_LISTS_SUCCESS,
            responseData: responseData,
            reset
        }
    }

    function failure(errorCode, errorMessage) {
        return {
            type: friendsConstants.FETCH_PENDING_FRIEND_LISTS_FAILURE,
            errorCode: errorCode,
            errorMessage: errorMessage
        };
    }
}

function respondFriend(id, operation) {
    console.log('friendActions.respondFriend.operation=', {id, operation});
    return dispatch => {
        dispatch(request());
        apiService.respondFriend(id, operation)
            .then(response => {
                console.log('respondFriend response=', response);
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error && error.response && error.response.status && error.response.data.message) {
                    dispatch(failure(error.response.status, error.response.data.message))
                } else {
                    dispatch(failure(-1));
                }
            });
    };

    function request() {
        return {
            type: friendsConstants.RESPOND_FRIEND_REQUEST
        };
    }

    function success(responseData) {
        return {
            type: friendsConstants.RESPOND_FRIEND_SUCCESS,
            responseData: responseData
        }
    }

    function failure(errorCode, errorMessage) {
        return {
            type: friendsConstants.RESPOND_FRIEND_FAILURE,
            errorCode: errorCode,
            errorMessage: errorMessage
        };
    }
}

function listenFriendRequest() {
    return dispatch => {
        const ref = firebase.database().ref();

        const userRef = ref.child('friend_request').child("2");

        userRef.on("child_added", snapshot => {
            onChildAddedOrChanged(snapshot);
        });

        userRef.on("child_changed", snapshot => {
            onChildAddedOrChanged(snapshot);
        });

        function onChildAddedOrChanged(snapshot) {
            if (snapshot && snapshot.val()) {
                const {id, name, time} = snapshot.val();
                console.log('child received=', {id, name, time});
                dispatch(onFriendRequestReceived(id, name, time));
            }
        }
    };

    function onFriendRequestReceived(id, name, time) {
        return {
            type: friendsConstants.FRIEND_REQUEST_RECEIVED,
            friend: {id, name, time}
        }
    }
}

function checkFriendStatus(friend_uid) {
    return dispatch => {
        dispatch(request());
        apiService.checkFriendStatus(friend_uid)
            .then(response => {
                console.log('checkFriendRequest response=', response);
                dispatch(success(response.data));
            })
            .catch(error => {
                if (error && error.response && error.response.status && error.response.data.message) {
                    dispatch(failure(error.response.status, error.response.data.message))
                } else {
                    dispatch(failure(-1));
                }
            });
    };

    function request() {
        return {
            type: friendsConstants.CHECK_FRIEND_STATUS_REQUEST
        };
    }

    function success(responseData) {
        return {
            type: friendsConstants.CHECK_FRIEND_STATUS_SUCCESS,
            responseData: responseData
        }
    }

    function failure(errorCode, errorMessage) {
        return {
            type: friendsConstants.CHECK_FRIEND_STATUS_FAILURE,
            errorCode: errorCode,
            errorMessage: errorMessage
        };
    }
}
