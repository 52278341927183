import React, {Component} from 'react';
import ImageCrop from "../register/imageCrop";
import '../../css/style.css'
import {connect} from "react-redux";
import {helperActions} from "../../actions/helperActions";
import {history} from "../app/App";
import {utils} from "../../helper/utils";
import {profileActions} from "../../actions/profileActions";
import {loginActions} from "../../actions/loginActions";
import {default as LOCALE} from "../../localization";
import {userConstants} from "../../constants/userConstants";

class UpdateProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            seeking: 'M',
            countryIndex: 0,
            stateIndex: 0,
            cityIndex: 0,
            noImageSelected: false,
            gender: '',
            age: '',
            genderEmpty: false,
            dobEmpty: false,
            validDob: true,
        };

        this.aboutMeTextAreaRef = React.createRef();
        this.myInterestTextAreaRef = React.createRef();
    }

    populateCountryList = () => {
        const {countryList} = this.props.countryReducer;

        let items = [];
        for (let i = 0; i < countryList.length; i++) {
            items.push(<option key={i} value={i}>{countryList[i]}</option>)
        }
        return items;
    };

    populateStateList = () => {
        const {stateList} = this.props.countryReducer;

        let items = [];
        for (let i = 0; i < stateList.length; i++) {
            items.push(<option key={i} value={i}>{stateList[i]}</option>)
        }
        return items;
    };

    populateCityList = () => {
        const {cityList} = this.props.countryReducer;
        let items = [];
        for (let i = 0; i < cityList.length; i++) {
            items.push(<option key={i} value={i}>{cityList[i]}</option>)
        }
        return items;
    };

    loadInitialData = async (initialData) => {

        if (!initialData) {
            return;
        }

        const {
            // image,
            // display_name,
            age,
            // countryName,
            // stateName,
            // cityName,
            gender,
            seeking,
            // about_me,
            // my_interest,
            // profile_questions
        } = initialData;

        const {countryIndex, stateIndex, cityIndex} =
            await utils.getCountryStateCityIndices(initialData.country_id, initialData.state_id, initialData.city_id);

        this.setState({
            seeking,
            age,
            gender,
            countryIndex,
            stateIndex,
            cityIndex
        });


    };

    async componentDidMount() {
        console.log('componentDidMount');

        const {createNew, initialData} = this.props.location.state;

        console.log('this.props.state=', {createNew, initialData});

        if (!createNew && initialData) {
            await this.loadInitialData(initialData);
        }

        const {countryIndex, stateIndex} = this.state;

        console.log('countryStateCity indices=', {countryIndex, stateIndex});

        this.props.loadCountryStateCityList(countryIndex, stateIndex);
    }

    onInput = (e) => {
        const {name, value} = e.target;
        this.setState({
            [name]: value
        });
    };

    onSeekingSelected = (value) => {
        this.setState({seeking: value})
    };

    onCountrySelected = (selectedCountryIndex) => {
        this.props.loadStateCityList(selectedCountryIndex);
        this.setState({countryIndex: selectedCountryIndex, stateIndex: 0, cityIndex: 0})
    };

    onStateSelected = (selectedStateIndex) => {
        const {countryIndex} = this.state;
        this.props.loadCityList(countryIndex, selectedStateIndex);
        this.setState({stateIndex: selectedStateIndex, cityIndex: 0})
    };

    onCitySelected = (selectedCityIndex) => {
        this.setState({cityIndex: selectedCityIndex})
    };

    onImageSelected = (image_orig, image_350, image_250) => {
        this.setState({image: {image_orig, image_350, image_250}});
        console.log('onImageSelected=', this.state.image);
    };


    isFieldValid = (fieldValue) => {
        return fieldValue.toString().length > 0;
    };

    maxDate = () => {
        let maxDate = Date.parse(new Date()) - 568025136000;
        let date = new Date(maxDate);

        let dd = date.getDate();
        let mm = date.getMonth() + 1; //January is 0!
        let yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }
        date = yyyy + '-' + mm + '-' + dd;

        return date;

    };

    isValidDOB = (dob) => {
        //24*60*60*1000*365.25=31557600000
        let age = utils.getAge(dob);

        console.log("age limit is ", dob, age);
        return age < 18;
    };

    updateProfile = async () => {
        const {image, seeking, countryIndex, stateIndex, cityIndex, gender, age} = this.state;
        const {createNew} = this.props.location.state;

        if (!image && createNew) {
            this.setState({noImageSelected: true});
            return;
        }
        this.setState({noImageSelected: false});

        if (!this.isFieldValid(gender)) {
            this.setState({genderEmpty: true});
            return
        }
        if (!this.isFieldValid(seeking)) {
            this.setState({seeking: 'M'});
            return
        }
        this.setState({genderEmpty: false});

        if (!this.isFieldValid(age)) {
            this.setState({dobEmpty: true});
            return
        }
        this.setState({dobEmpty: false});

        if (this.isValidDOB(age)) {
            this.setState({validDob: false});
            return;
        }
        this.setState({validDob: true});

        const {countryId, stateId, cityId} = await utils.getCountryStateCityIds(countryIndex, stateIndex, cityIndex);

        console.log('updateProfile called');
        this.props.updateProfile(
            image,
            gender,
            age,
            seeking,
            countryId,
            stateId,
            cityId,
            this.aboutMeTextAreaRef.current.value,
            this.myInterestTextAreaRef.current.value,
            createNew
        );
    };

    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {countryIndex, stateIndex, cityIndex, seeking, noImageSelected, validDob, dobEmpty, genderEmpty, gender, age} = this.state;
        const {updatingProfile, invalidData, internalError, errorMessage} = this.props.profileReducer;
        console.log("tha age is ", countryIndex,stateIndex,cityIndex);
        const {initialData, includesLogout} = this.props.location.state;
        const {loggingOut} = this.props.logoutReducer;


        let image_orig, about_me, my_interest;
        if (initialData && initialData.image) {
            image_orig = initialData.image.image_orig;
            about_me = initialData.about_me;
            my_interest = initialData.my_interest;
        }

        return (
            <div className='home-page-wrapper edit-profile'>
                <div className='home-page'>
                    <div className="top-bar">
                        <img onClick={() => {
                            history.goBack();
                        }} src={require('../../img/back.png')} alt="back-button"/>
                        <h3>{LOCALE.edit_profile}</h3>
                    </div>
                    <div className="homepage-content">
                        <div className="upload-image">
                            <ImageCrop defaultImage={image_orig}
                                       onImageSelected={this.onImageSelected}/>

                            {noImageSelected && <div>{LOCALE.please_select_the_image}</div>}
                        </div>
                        <br/>
                        <div className="edit-profile-width">
                            <div className="text-left">
                                {genderEmpty && <div>{LOCALE.gender_required}</div>}
                                <label className="text-left">{LOCALE.gender}</label>
                                <label className="radio-btn"> {LOCALE.male} <input type="radio" name="gender" value="M"
                                                                          checked={gender === 'M'}
                                                                          onChange={this.onInput}/>
                                    <span className="checkmark"></span>
                                </label>

                                <label className="radio-btn"> {LOCALE.female} <input type="radio" name="gender" value="F"
                                                                            checked={gender === 'F'}
                                                                            onChange={this.onInput}/>
                                    <span className="checkmark"></span>
                                </label>

                                <label className="radio-btn"> {LOCALE.couples} <input type="radio" name="gender" value="C"
                                                                           checked={gender === 'C'}
                                                                           onChange={this.onInput}/>
                                    <span className="checkmark"></span>
                                </label>

                            </div>


                            {dobEmpty && <div>{LOCALE.dob_required}</div>}
                            {!validDob && <div>{LOCALE.must_be_18}</div>}

                            <div className="edit-profile-dob">
                                <label className="text-left"> {LOCALE.birthday}</label>
                                <input className={!validDob ? "input-field input-field-error" : "input-field"}
                                       type="date"
                                       name="age"
                                       value={age}
                                       max={this.maxDate()}
                                       onChange={this.onInput}
                                />
                            </div>

                            <label className="text-left">{LOCALE.seeking}</label>
                            <div id="basic-select">
                                <select className="input-field" value={seeking} onChange={(e) => {
                                    this.onSeekingSelected(e.target.value)
                                }}>
                                    <option value="M">{LOCALE.male}</option>
                                    <option value="F">{LOCALE.female}</option>
                                    <option value="C">{LOCALE.couples}</option>
                                </select>
                            </div>

                            <label className="text-left">{LOCALE.country}<span>*</span></label>
                            <div id="basic-select">
                                <select className="input-field" value={countryIndex} onChange={(e) => {
                                    this.onCountrySelected(e.target.value)
                                }}>
                                    {this.populateCountryList()}
                                </select>
                            </div>

                            <label className="text-left">{LOCALE.state}<span>*</span></label>
                            <div id="basic-select">
                                <select className="input-field" value={stateIndex} onChange={(e) => {
                                    this.onStateSelected(e.target.value)
                                }}>
                                    {this.populateStateList()}
                                </select>
                            </div>

                            <label className="text-left">{LOCALE.city}<span>*</span></label>
                            <div id="basic-select">
                                <select className="input-field" value={cityIndex} onChange={(e) => {
                                    this.onCitySelected(e.target.value)
                                }}>
                                    {this.populateCityList()}
                                </select>
                            </div>

                            <label className="text-left">{LOCALE.about_me}</label>
                            <textarea className="input-field textarea" defaultValue={about_me}
                                      ref={this.aboutMeTextAreaRef}/>

                            <label className="text-left">{LOCALE.my_interests}</label>
                            <textarea className="input-field textarea" defaultValue={my_interest}
                                      ref={this.myInterestTextAreaRef}/>
                            {invalidData && <div>{errorMessage}</div>}
                            {internalError && <div>Internal Error</div>}
                            <button className='btn1'
                                    onClick={this.updateProfile}>{updatingProfile ? LOCALE.updating_profile : LOCALE.update}</button>
                            {includesLogout && <button className='btn01'
                                                       onClick={() => this.props.logout()}>{loggingOut ? LOCALE.logging_out : LOCALE.logout}</button>
                            }

                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

const mapState = (state) => {
    return {
        countryReducer: state.countryReducer,
        profileReducer: state.profileReducer,
        logoutReducer: state.logoutReducer

    };
};

const actionCreators = {
    loadCountryStateCityList: helperActions.loadCountryStateCityList,
    loadStateCityList: helperActions.loadStateCityList,
    loadCityList: helperActions.loadCityList,
    updateProfile: profileActions.updateProfile,
    logout: loginActions.logout,
};

export default connect(mapState, actionCreators)(UpdateProfile);
