import React, {Component} from 'react';
import SingleChoiceQuestion from "./singleChoiceQuestion";
import FreeTextQuestion from "./freeTextQuestion";
import {connect} from "react-redux";
import {profileActions} from "../../../actions/profileActions";
import MultipleChoiceQuestion from "./multipleChoiceQuestion";
import Loader from "../../Loader/Loader";
import {history} from "../../app/App";
import {default as LOCALE} from "../../../localization";
import {userConstants} from "../../../constants/userConstants";

class ProfileQuestions extends Component {

    constructor(props) {
        super(props);

        this.state = {};
        this.answers = {};
    }

    componentDidMount() {
        this.props.getProfileQuestions();
    }

    onAnswerChanged = (profile_setup_id, answer) => {
        if (!this.answers[profile_setup_id]) {
            this.answers[profile_setup_id] = {};
        }
        this.answers[profile_setup_id].answer = answer;

        console.log('onAnswerChanged. this.answers=', this.answers);
    };

    onInputError = (profile_setup_id, error) => {
        if (!this.answers[profile_setup_id]) {
            this.answers[profile_setup_id] = {};
        }

        this.answers[profile_setup_id].error = error;

        console.log('onAnswerChanged. this.answers=', this.answers);
    };

    updateProfileQuestions = () => {
        let answers = [];
        for (const key in this.answers) {
            if (this.answers.hasOwnProperty(key)) {
                const {answer, error} = this.answers[key];
                if (error) {
                    this.setState({inputError: true});
                    console.log('all required fields are not selected');
                    return;
                }
                answers.push(answer);
            }
        }
        this.setState({inputError: false});
        this.props.updateProfileQuestions(answers);

        console.log('updateProfileQuestions=', answers);
    };

    fillAnswers = (questions) => {
        const initialAnswers = this.props.location.state.initialData;

        console.log('initialAnswers=', initialAnswers);

        for (let i = 0; i < initialAnswers.length; i++) {
            const initialAnswer = initialAnswers[i];

            for (let j = 0; j < questions.length; j++) {
                const question = questions[j];

                if (initialAnswer.profile_question_id === question.profile_setup_id) {
                    switch (question.field_type_id) {
                        case "1"://single choice questions
                            questions[j] = {
                                ...question,
                                selected_question_option_id: initialAnswer.profile_question_option_id
                            };
                            break;
                        case "2"://free text questions
                            questions[j] = {
                                ...question,
                                option_value: initialAnswer.option_value
                            };
                            break;
                        case "3"://multiple choice questions
                            if (questions[j].selected_question_option_ids) {
                                const new_selected_question_option_ids = questions[j].selected_question_option_ids;
                                new_selected_question_option_ids.push(initialAnswer.profile_question_option_id);
                                questions[j] = {
                                    ...question,
                                    selected_question_option_ids: new_selected_question_option_ids
                                };
                            } else {
                                questions[j] = {
                                    ...question,
                                    selected_question_option_ids: [initialAnswer.profile_question_option_id]
                                };
                            }
                            break;
                        default:
                            break;
                    }
                    break;
                }
            }

        }
        const singleChoiceQuestions = questions.filter(question => (question.field_type_id === '1'));
        const freeTextQuestions = questions.filter(question => (question.field_type_id === '2'));
        const multipleChoiceQuestions = questions.filter(question => (question.field_type_id === '3'));

        return {singleChoiceQuestions, freeTextQuestions, multipleChoiceQuestions};
    };

    getDiv = (questions) => {
        const {singleChoiceQuestions, multipleChoiceQuestions, freeTextQuestions} = this.fillAnswers(questions);
        const {inputError} = this.state;

        return <div className="home-page-wrapper">
            <div className="home-page">
                <div className="top-bar">
                    <img onClick={() => {
                        history.goBack();
                    }} src={require('../../../img/back.png')} alt="back-button"/>
                    <h3>{LOCALE.profile_questions}</h3>
                </div>

                <div className="homepage-content">
                    {singleChoiceQuestions.map((question, i) => (
                        <div key={i}>
                            <SingleChoiceQuestion
                                question={question}
                                onAnswerChanged={this.onAnswerChanged}
                                onInputError={this.onInputError}/>
                            <br/>
                        </div>
                    ))}

                    {multipleChoiceQuestions.map((question, i) => (
                        <div key={i}>
                            <MultipleChoiceQuestion
                                question={question}
                                onAnswerChanged={this.onAnswerChanged}
                                onInputError={this.onInputError}/>
                            <br/>
                        </div>
                    ))}

                    {freeTextQuestions.map((question, i) => (
                        <div key={i}>
                            <FreeTextQuestion
                                question={question}
                                onAnswerChanged={this.onAnswerChanged}
                                onInputError={this.onInputError}/>
                            <br/>
                        </div>
                    ))}

                    <button className='btn1 questions-btn' onClick={this.updateProfileQuestions}>{LOCALE.update}</button>
                    {inputError && <div>{LOCALE.fill_all_required}</div>}
                </div>
            </div>
        </div>
    };

    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {gettingProfileQuestions, responseData, invalidData, internalError, errorMessage} = this.props.getProfileQuestionsReducer;
        const {updatingProfile, invalidDataUpdateProfile, internalErrorUpdateProfile, errorMessageUpdateProfile} = this.props.profileReducer;

        return (
            <div className="home-page-wrapper">
                <div className="home-page">
                    <Loader/>
                    {gettingProfileQuestions && <div>{LOCALE.getting_profile_question}</div>}
                    {invalidData && <div>{errorMessage}</div>}
                    {internalError && <div>{LOCALE.internal_error}</div>}
                    {responseData && this.getDiv(responseData)}
                    {updatingProfile && <div>{LOCALE.updating_profile}</div>}
                    {invalidDataUpdateProfile && <div>{errorMessageUpdateProfile}</div>}
                    {internalErrorUpdateProfile && <div>{LOCALE.internal_error}</div>}
                </div>
            </div>
        );
    }
}

const mapState = (state) => {
    return {
        getProfileQuestionsReducer: state.getProfileQuestionsReducer,
        profileReducer: state.profileReducer
    };
};

const actionCreators = {
    getProfileQuestions: profileActions.getProfileQuestions,
    updateProfileQuestions: profileActions.updateProfileQuestions,
};

export default connect(mapState, actionCreators)(ProfileQuestions);
