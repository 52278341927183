import React, {Component} from 'react';
import {friendActions} from "../../actions/friendActions";
import {history} from "../app/App";
import {utils} from "../../helper/utils";
import {connect} from "react-redux";
import Loader from "../Loader/Loader";
import {default as LOCALE} from "../../localization";
import {userConstants} from "../../constants/userConstants";

class PendingFriendRequests extends Component {
    componentDidMount() {
        this.props.pendingFriendsRequestList()
    }

    handleImageClick = (userProfileId) => {
        history.push(`/users/${userProfileId}`)
    };
    paginateIfNecessary = (e) => {
        const {gettingPendingRequests, lastPage, page} = this.props.pendingFriendRequestsReducer;
        console.log('paginateIfNecessary', {
            scrollHeight: e.target.scrollHeight,
            scrollTop: e.target.scrollTop,
            clientHeight: e.target.clientHeight
        });
        const bottom = Math.round(e.target.scrollHeight) - Math.round(e.target.scrollTop) === Math.round(e.target.clientHeight);

        console.log('paginateIfNecessary bottom', bottom);

        if (bottom) {
            if (lastPage || gettingPendingRequests) {
                gettingPendingRequests ? console.log('getting search users already') : console.log('last page reached');
            } else {
                console.log('getting more users', page);
                this.props.pendingFriendsRequestList(page + 1);
            }
        }
    };

    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {gettingPendingRequests, pendingFriendRequests, invalidData, internalError, errorMessage} = this.props.pendingFriendRequestsReducer;
        console.log('searchResults render users=', gettingPendingRequests);
        if (pendingFriendRequests.length > 0) {
            return (
                <div className='home-page-wrapper search-result'>
                    <div className="home-page">
                        <div className="top-bar">
                            <img onClick={() => {
                                history.goBack();
                            }} src={require('../../img/back.png')} alt="back-button"/>
                            <h3>{LOCALE.friend_requests}</h3>
                        </div>

                        <div className='scrollx' onScroll={this.paginateIfNecessary}>
                            {
                                pendingFriendRequests.map((pendingFriendRequest, i) => (
                                    <div
                                        onClick={this.handleImageClick.bind(this, pendingFriendRequest.user_profile_id)}
                                        key={i}>
                                        <div className="searched-image">
                                            <div className="searched-img">
                                                <img
                                                    key={i}
                                                    className='img-search' src={pendingFriendRequest.image.image_250}
                                                    alt='user'/>
                                            </div>
                                            <div className="searched-det">
                                                <label>{`${pendingFriendRequest.display_name}, ${utils.getAge(pendingFriendRequest.age)}`}</label>
                                                <br/>
                                                <label>{pendingFriendRequest.countryName}, </label>
                                                {pendingFriendRequest.stateName !== 'None' ?
                                                    <label>{pendingFriendRequest.stateName}, </label> : ''}
                                                <label>{pendingFriendRequest.cityName}</label>
                                                <br/><br/>
                                            </div>
                                        </div>
                                    </div>
                                ))

                            }
                        </div>
                        {gettingPendingRequests && <Loader/>}

                    </div>
                </div>
            );

        }

      else  if (gettingPendingRequests) {
            return (
                <div className="home-page-wrapper">
                    <div className="home-page">

                        <div className="top-bar">
                            <img onClick={() => {
                                history.goBack();
                            }} src={require('../../img/back.png')} alt="back-button"/>
                            <h3>{LOCALE.friend_requests}</h3>
                        </div>
                        <Loader/>

                    </div>
                </div>
            );
        }
        else {
            return (
                <div className="home-page-wrapper">
                    <div className="home-page">
                        <div className="top-bar">
                            <img onClick={() => {
                                history.goBack();
                            }} src={require('../../img/back.png')} alt="back-button"/>
                            <h3>{LOCALE.friend_requests}</h3>
                        </div>
                        <div className="homepage-content">
                            {internalError && <div>{LOCALE.internal_error}</div>}
                            {invalidData && <div>{errorMessage}</div>}
                            <h3> {LOCALE.no_pending_requests}</h3>

                            {/*        <div>Back to Profile?
                                <button className="btn-clear top-space"
                                        onClick={() => {
                                            history.goBack();
                                        }}><strong>Click here</strong>
                                </button>
                            </div>*/}
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapState = (state) => {
    return {
        pendingFriendRequestsReducer: state.pendingFriendRequestsReducer

    }
};

const actionCreators = {
    pendingFriendsRequestList: friendActions.pendingFriendsRequestList

};

export default connect(mapState, actionCreators)(PendingFriendRequests);
