import React, {Component} from 'react';
import '../../css/style.css'
import {connect} from "react-redux";
import {messageActions} from "../../actions/messageActions";
import {history} from "../app/App";
import SideDrawer from "../SideDrawer/SideDrawer";
import Tab from "../tab/tab";
import Loader from "../Loader/Loader";
import {notificationActions} from "../../actions/notificationActions";
import {userConstants} from "../../constants/userConstants";
import {default as LOCALE} from "../../localization";

class Message extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sideDrawerOpen: false
        }
    }

    componentDidMount() {
        //exclude from none
        this.props.excludeNotification('message', '');
        this.props.getMessageUsers();
    }

    handleClick = (user) => {
        history.push('/chatFeed', user);
    };

    drawerToggleClickHandler = () => {
        this.setState(prevState => {
            return {sideDrawerOpen: !prevState.sideDrawerOpen}
        })
    };

    dismissSideDrawer = () => {
        this.setState({sideDrawerOpen: false})
    };

    paginateIfNecessary = (e) => {
        const {gettingMessageUsers, lastPage, page} = this.props.getMessageUsersReducer;

        console.log('paginateIfNecessary', {
            scrollHeight: e.target.scrollHeight,
            scrollTop: e.target.scrollTop,
            clientHeight: e.target.clientHeight
        });
        const bottom = Math.round(e.target.scrollHeight) - Math.round(e.target.scrollTop) === Math.round(e.target.clientHeight);

        console.log('paginateIfNecessary bottom', bottom);

        if (bottom) {
            if (lastPage || gettingMessageUsers) {
                gettingMessageUsers ? console.log('getting message users already') : console.log('last page reached');
            } else {
                console.log('getting more users', page);
                this.props.getMessageUsers(page + 1);
            }
        }
    };

    getMessageCount = (messages, userId) => {
        let count = 0;

        messages.forEach(message => {
            if (message.userId === userId) {
                count += 1;
            }
        });

        return count > 0 ? `(${count})` : '';
    };

    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {gettingMessageUsers, messageUsers, invalidData, internalError, errorMessage} = this.props.getMessageUsersReducer;
        const {sideDrawerOpen} = this.state;

        // const {messages} = this.props.notificationReducer;

        console.log('messageUsers render users=', messageUsers);

        return (
            <div className='home-page-wrapper search-result'>
                <div className="home-page">
                    <div className='scrollx' onScroll={this.paginateIfNecessary}>
                        {
                            messageUsers.map((user, i) => (
                                <div onClick={this.handleClick.bind(this, user)} key={i}>
                                    <div className="searched-image">
                                        <div className="searched-img">
                                            <img
                                                key={i}
                                                className='img-search' src={user.image.image_250}
                                                alt='user'/>
                                        </div>
                                        <div className="searched-det">
                                            <label>{user.display_name} {user.unread_count>0?`(${user.unread_count})` : ''}</label>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    {/*{gettingMessageUsers && <div>Getting chats...</div>}*/}
                    {/*<Loader/>*/}
                    {gettingMessageUsers && <Loader/>}
                    {invalidData && <div>{errorMessage ? LOCALE.invalid_data : "{<Loader/>}"}</div>}
                    {internalError && <div>{internalError ? LOCALE.internal_error : "{<Loader/>}"}</div>}
                    {!invalidData && !gettingMessageUsers && !internalError && messageUsers.length === 0 &&
                    <div>{LOCALE.no_friends_to_chat}</div>}

                    <div className="homepage-content2">
                        <SideDrawer show={sideDrawerOpen} onOutsideClick={this.dismissSideDrawer}/>
                    </div>

                    <div className='tab-footer'>
                        <Tab drawerClickHandler={this.drawerToggleClickHandler}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapState = (state) => {
    return {
        getMessageUsersReducer: state.getMessageUsersReducer,
        notificationReducer: state.notificationReducer
    }
};

const actionCreators = {
    getMessageUsers: messageActions.getMessageUsers,
    excludeNotification: notificationActions.excludeNotification
};

export default connect(mapState, actionCreators)(Message);
