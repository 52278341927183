import React, {Component} from 'react';
import '../../css/style.css'
import Backdrop from "../Backdrop/Backdrop";
import Modal from "react-modal";
import {loginActions} from "../../actions/loginActions";
import {connect} from "react-redux";
import {profileActions} from "../../actions/profileActions";
import {default as LOCALE} from "../../localization";
import {userConstants} from "../../constants/userConstants";

class SideDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            shown: true

        };
    }

    componentDidMount() {
        this.props.getProfile("me");
    }

    handleOpenModal = () => {
        this.setState({
            showModal: true,
        });
    };

    handleCloseModal = () => {
        this.setState({showModal: false});
        window.location.reload();
    };

    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {show} = this.props;
        const {loggingOut, internalError} = this.props.logoutReducer;
        const {responseData} = this.props.getProfileReducer;

        return (
            <div>

                <nav className={show ? 'side-drawer open scrollx' : 'side-drawer scrollx'}>
                    <div className="menu-image">
                        {responseData &&
                        <img src={responseData.image.image_250} alt="notification-icon"/>
                        }
                    </div>
                    <ul>
                        <li><a href="/users/me">{LOCALE.my_profile}</a></li>
                        <li><a href="/viewSentFriendRequests">{LOCALE.pending_requests}</a></li>
                        <li><a href="/viewFriendsList">{LOCALE.friend_lists}</a></li>
                        <li><a href="/pendingFriendRequests">{LOCALE.friend_requests}</a></li>
                        <li><a href="/nearMe">{LOCALE.near_me}</a></li>
                        <li><a href="/membershipPlans">{LOCALE.membership_plans}</a></li>
                        <li><a href="/advanceSearch">{LOCALE.advanced_search}</a></li>
                        {/*eslint-disable-next-line*/}
                        <li><a onClick={this.handleOpenModal}>{LOCALE.logout}</a></li>
                    </ul>
                    <div>
                        <Modal
                            isOpen={this.state.showModal}
                            contentLabel="Minimal Modal Example"
                            ariaHideApp={false}
                            onClose={this.handleCloseModal}
                        >
                            <img className="cancel-icon" onClick={this.handleCloseModal}
                                 src={require('../../img/cancel.png')} alt="cancel-button"/>
                            <h1>Logout</h1>
                            {loggingOut ? <div> {LOCALE.logging_out}</div> : <div>
                                {internalError && <div>{LOCALE.internal_error}</div>}
                                <h3>{LOCALE.sure_want_to_logout}</h3>
                                <button disabled={loggingOut} onClick={this.handleCloseModal}>{LOCALE.no}</button>
                                <button disabled={loggingOut} onClick={() => this.props.logout()}>{LOCALE.yes}</button>
                            </div>}
                        </Modal>
                    </div>
                </nav>


                {show && <Backdrop click={this.props.onOutsideClick}/>}

            </div>

        );
    }
}


const mapState = (state) => {
    return {
        getProfileReducer: state.getProfileReducer,
        logoutReducer: state.logoutReducer
    }
};

const actionCreators = {
    logout: loginActions.logout,
    getProfile: profileActions.getProfile,

};

export default connect(mapState, actionCreators)(SideDrawer);
