import React, {Component} from 'react';
import {connect} from 'react-redux'
import {history} from "../app/App";
import {registrationAction} from "../../actions/registrationAction";
import {default as LOCALE} from "../../localization";
import {userConstants} from "../../constants/userConstants";

class OtpRequestRegistration extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userEmail: '',
            fieldEmpty: false,
            validEmail:true,
        };
    }

    componentDidMount() {
        this.props.requestOtpRegistration();

    }

    onInput = (e) => {
        const {name, value} = e.target;
        this.setState({[name]: value});
    };

     isValidEmail=(userEmail) =>{
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(userEmail);
    };

    requestOtpRegistration = e => {
        e.preventDefault();
        const {userEmail} = this.state;
        if (userEmail.length === 0) {
            this.setState({fieldEmpty: true});
            return;
        }


        if (!this.isValidEmail(userEmail)){
            this.setState({validEmail:false});
            return;
        }

        this.setState(
            {
                fieldEmpty: false,
                validEmail:true,
            }
        );
        this.props.requestOtpRegistration(userEmail);
    };

    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {sendingOTP, invalidData, internalError, errorMessage} = this.props;
        const {userEmail, fieldEmpty,validEmail} = this.state;
        console.log("the error message is ",errorMessage);

        return (
            <div className="login-page">
                <div className="login-form">
                    <h3 className="login-page-title forgot-title">{LOCALE.register}</h3>

                    <div className="top-space forgot-msg">
                        {fieldEmpty && <div>{LOCALE.enter_both_name_and_password}</div>}
                        {invalidData && <div>{errorMessage}</div>}
                        {!validEmail && <div>{LOCALE.enter_valid_email}</div>}
                        {internalError && <div>{LOCALE.internal_error}</div>}
                    </div>

                    <input disabled={sendingOTP}
                           className={fieldEmpty || invalidData || internalError ? "input-field input-field-error" : "input-field"}
                           type='text'
                           name='userEmail'
                           value={userEmail}
                           placeholder={LOCALE.email_address}
                           onChange={this.onInput}/>

                    <div>
                        <button className={sendingOTP ? "btn1 login-btn-disable" : "btn1"}
                                onClick={this.requestOtpRegistration}>{sendingOTP ? "" : LOCALE.send_otp}
                        </button>
                        {sendingOTP && <div className="lds-dual-ring"/>}
                    </div>

                    <div>{LOCALE.back_to_login}
                        <button className="btn-clear top-space"
                                onClick={() => {
                                    history.goBack();
                                }}><strong>{LOCALE.click_here}</strong>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}


const actionCreators = {
    requestOtpRegistration: registrationAction.requestOtpRegistration,
};

const mapState = (state) => {
    const {sendingOTP, invalidData, internalError, responseData, errorMessage} = state.otpRequestRegisterReducer;
    return {sendingOTP, invalidData, internalError, responseData, errorMessage};
};

export default connect(mapState, actionCreators)(OtpRequestRegistration);
