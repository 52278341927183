import React, {Component} from 'react';
import {default as LOCALE} from "../../../localization";
import {userConstants} from "../../../constants/userConstants";

class FreeTextQuestion extends Component {

    constructor(props) {
        super(props);

        //initialization
        const {profile_setup_id, option_value, required} = this.props.question;

        if (required === 'Y' && !option_value) {
            this.props.onInputError(profile_setup_id, true);
            this.state = {inputError: true};
        } else {
            this.state = {inputError: false};
        }
    }

    onAnswerChanged = (value) => {
        const {profile_setup_id, field_type_id, required} = this.props.question;

        if (required === 'Y' && !value) {
            this.setState({inputError: true});
            this.props.onInputError(profile_setup_id, true);
            return;
        }

        this.setState({inputError: false});
        this.props.onInputError(profile_setup_id, false);

        this.props.onAnswerChanged(profile_setup_id, {
            field_type_id,
            profile_setup_id,
            question_option_id: 0,
            option_value: value,
        });
    };

    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {question_name, option_value, max_length, required} = this.props.question;
        const {inputError} = this.state;

        return (
            <div className="edit-questions-text">
                {inputError && <div>{question_name} {LOCALE.field_cannot_be_empty}</div>}
                <label>{question_name}{required === 'Y' ? '*' : ''}</label><br/>
                <textarea className={inputError ? "input-field-error" : ""}
                    maxLength={max_length}
                    onChange={(e) => {
                        this.onAnswerChanged(e.target.value)
                    }} defaultValue={option_value}/>
            </div>
        );
    }
}

export default FreeTextQuestion;
