import React, {Component} from 'react';
import {userActions} from "../../actions/userActions";
import {connect} from "react-redux";
import {utils} from "../../helper/utils";
import {history} from "../app/App";
import Loader from "../Loader/Loader";
import {default as LOCALE} from "../../localization";
import {userConstants} from "../../constants/userConstants";

class UserStack extends Component {

    constructor(props) {
        super(props);

        this.state = {
            index: 0,
            imageLoading: false,
            paginationOffset: 0.75
        }
    }

    componentDidMount() {
        console.log('componentDidMount');
        this.props.nextUsers();
    }

    handleImageClick = (userProfileId) => {
        console.log('handleClick userProfileId=', userProfileId);
        history.push(`/users/${userProfileId}`)
    };

    paginateIfNecessary = () => {
        const {totalUsers, gettingUsers, lastPage, page} = this.props.getUserReducer;
        const {index, paginationOffset} = this.state;

        if (index >= Math.trunc(totalUsers * paginationOffset)) {
            if (lastPage || gettingUsers) {
                gettingUsers ? console.log('getting users already') : console.log('last page reached');
            } else {
                this.props.nextUsers(page + 1);
            }
        }
    };

    onYesClicked = () => {
        this.paginateIfNecessary();

        const {users} = this.props.getUserReducer;
        const {index} = this.state;

        this.props.respondUser(users[index].ID);

        this.setState((prevState) => ({
            imageLoading: true,
            index: prevState.index + 1
        }));
    };

    onNoClicked = () => {
        this.paginateIfNecessary();

        this.setState((prevState) => ({
            imageLoading: true,
            index: prevState.index + 1
        }));
    };

    onImageLoaded = () => {
        this.setState({imageLoading: false})
    };

    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {gettingUsers, users} = this.props.getUserReducer;
        const {imageLoading, index} = this.state;

        if (users.length > 0 && index < users.length) {
            const {
                // ID,
                // about_me,
                age,
                // city_id,
                // country_id,
                display_name,
                // gender,
                image,
                // make_private,
                // my_interest,
                // seeking,
                // state_id,
                // status_id,
                user_profile_id,
                countryName,
                stateName,
                cityName
            } = users[index];

            const {
                image_orig,
                // image_250,
                // image_350
            } = image;

            return (
                <div className='user-stack'>

                    <div className="home-image">
                        <img hidden={imageLoading} className='Image-Placeholder' src={image_orig}
                             onLoad={this.onImageLoaded}
                             onClick={this.handleImageClick.bind(this, user_profile_id)}
                             alt="clock-sample"/>
                        <label className={imageLoading ? "loading-text" : "div-hide"}><Loader /></label>
                    </div>
                    <label className="user-name">{`${display_name}, ${utils.getAge(age)}`}</label>
                    <div className="country-name">
                        <label>{countryName}, </label>
                        {stateName !== 'None' ? <label>{stateName}, </label> : ''}
                        <label>{cityName}</label>
                    </div>


                    <button className="btn-home-clear" onClick={this.onNoClicked}>No</button>
                    <button className="btn-home" onClick={this.onYesClicked}>Yes</button>
                </div>

            );
        } else if (gettingUsers) {
            return (
                <div className='user-stack getting-users'>
                    <Loader />
                </div>
            );
        } else {
            return (
                <div className='no-more-user'>
                    <img className="no-more-image" src={require('../../img/no-more-image.png')} alt="logo"/>
                    {LOCALE.no_more_members_availabe}
                </div>
            );
        }
    }
}

const mapState = (state) => {
    return {
        getUserReducer: state.getUserReducer,
        respondUserReducer: state.respondUserReducer
    };
};

const actionCreators = {
    nextUsers: userActions.nextUsers,
    respondUser: userActions.respondUser
};


export default connect(mapState, actionCreators)(UserStack);
