import {apiService} from "../services/apiService";
import {profileConstants} from "../constants/profileConstants";
import {history} from "../components/app/App";
import {utils} from "../helper/utils";

export const profileActions = {updateProfile, getProfile, getProfileQuestions, updateProfileQuestions};

function updateProfile(image, gender, age, seeking, countryId, stateId, cityId, aboutMe, myInterest, createNew) {
    return dispatch => {
        dispatch(request());
        apiService.updateProfile(image, gender, age, seeking, countryId, stateId, cityId, aboutMe, myInterest, createNew)
            .then(response => {
                console.log('updateProfile responseData=', response);

                const responseData = response.data;

                dispatch(success(responseData));

                history.goBack();
            })
            .catch(error => {
                    console.log('updateProfile error=', error);
                    if (error && error.response && error.response.status && error.response.data.message) {
                        dispatch(failure(error.response.status, error.response.data.message))
                    } else {
                        dispatch(failure(-1));
                    }
                }
            )
    };

    function request() {
        return {
            type: profileConstants.UPDATE_PROFILE_REQUEST
        };
    }

    function success(responseData) {
        return {
            type: profileConstants.UPDATE_PROFILE_SUCCESS,
            responseData: responseData
        }
    }

    function failure(errorCode, errorMessage) {
        return {
            type: profileConstants.UPDATE_PROFILE_FAILURE,
            errorCode: errorCode,
            errorMessage: errorMessage
        };
    }
}

function getProfile(userId) {
    return dispatch => {
        dispatch(request());
        apiService.getProfile(userId)
            .then(response => {
                console.log('viewProfile responseData=', response);

                utils.addCountryStateCityNameToUsers([response.data], (users) => {
                    utils.addProfileQuestionsNameToUser(users[0])
                        .then(newUser => {
                            response.data = newUser;
                            dispatch(success(response.data));
                        })
                        .catch(error => {
                            console.log('addProfileQuestionsNameToUser error=', error);
                            if (error && error.response && error.response.status && error.response.data.message) {
                                dispatch(failure(error.response.status, error.response.data.message))
                            } else {
                                dispatch(failure(-1));
                            }
                        });
                });
            })
            .catch(error => {
                    console.log('viewProfile error=', error);
                    if (error && error.response && error.response.status && error.response.data.message) {
                        dispatch(failure(error.response.status, error.response.data.message))
                    } else {
                        dispatch(failure(-1));
                    }
                }
            )
    };

    function request() {
        return {
            type: profileConstants.VIEW_PROFILE_REQUEST
        };
    }

    function success(responseData) {
        return {
            type: profileConstants.VIEW_PROFILE_SUCCESS,
            responseData: responseData
        }
    }

    function failure(errorCode, errorMessage) {
        return {
            type: profileConstants.VIEW_PROFILE_FAILURE,
            errorCode: errorCode,
            errorMessage: errorMessage
        };
    }
}

function getProfileQuestions() {
    return dispatch => {
        dispatch(request());
        apiService.getProfileQuestions()
            .then(response => {
                dispatch(success(response.data));
            })
            .catch(error => {
                    if (error && error.response && error.response.status && error.response.data.message) {
                        dispatch(failure(error.response.status, error.response.data.message))
                    } else {
                        dispatch(failure(-1));
                    }
                }
            )
    };

    function request() {
        return {
            type: profileConstants.GET_PROFILE_QUESTION_REQUEST
        };
    }

    function success(responseData) {
        return {
            type: profileConstants.GET_PROFILE_QUESTION_SUCCESS,
            responseData
        }
    }

    function failure(errorCode, errorMessage) {
        return {
            type: profileConstants.GET_PROFILE_QUESTION_FAILURE,
            errorCode: errorCode,
            errorMessage: errorMessage
        };
    }
}

function updateProfileQuestions(profileQuestions) {
    return dispatch => {
        dispatch(request());
        apiService.updateProfileQuestions(profileQuestions)
            .then(response => {
                console.log('updateProfileQuestions responseData=', response);

                const responseData = response.data;

                dispatch(success(responseData));

                history.goBack();
            })
            .catch(error => {
                    console.log('updateProfile error=', error);
                    if (error && error.response && error.response.status && error.response.data.message) {
                        dispatch(failure(error.response.status, error.response.data.message))
                    } else {
                        dispatch(failure(-1));
                    }
                }
            )
    };

    function request() {
        return {
            type: profileConstants.UPDATE_PROFILE_REQUEST
        };
    }

    function success(responseData) {
        return {
            type: profileConstants.UPDATE_PROFILE_SUCCESS,
            responseData: responseData
        }
    }

    function failure(errorCode, errorMessage) {
        return {
            type: profileConstants.UPDATE_PROFILE_FAILURE,
            errorCode: errorCode,
            errorMessage: errorMessage
        };
    }
}
