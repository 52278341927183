import React, {Component} from 'react';
import './App.css';
import {Route} from "react-router-dom";
import Login from '../login/login'
import {PrivateRoute} from "../privateRoute/privateRoute";
import Search from "../search/search";
import ViewProfile from "../profile/viewProfile";
import OtpRequest from "../resetPassword/otpRequest";
import OtpVerify from "../resetPassword/otpVerify";
import ChangePassword from "../resetPassword/resetPassword"
import otpRequestRegistration from "../register/otpRequestRegistration";
import otpVerifyRegistration from "../register/otpVerifyRegistration";
import Registration from "../register/registration";
import UpdateProfile from "../profile/updateProfile";
import ProfileQuestions from "../profile/profileQuestions/profileQuestions";
import ImageCrop from "../register/imageCrop";
import searchResults from "../search/searchResults";
import reportUser from "../reportUser/reportUser";
import AccountSettings from "../accountSettings/accountSettings";
import ChangeEmail from "../accountSettings/changeEmail";
import verifyEmail from "../accountSettings/verifyEmail";
import changePassword from "../accountSettings/changePassword";
import viewFriends from "../friends/viewFriends";
import sentFriendRequests from "../friends/sentFriendRequests";
import pendingFriendRequests from "../friends/pendingFriendRequests";
import membershipPlansList from "../plans/membershipPlansList";
import membershipPlansDetails from "../plans/membershipPlansDetails";
import nearMe from "../nearMe/nearMe";
import nearMeSettings from "../nearMe/nearMeSettings";
import logout from "../logout/logout";
import Message from "../message/message";
import ChatFeed from "../message/chatFeed";
import advanceSearch from "../search/advanceSearch";
import Localization from "../../localization/localization";
import home from "../home/home";

class App extends Component {
    static history;

    constructor(props) {
        super(props);
        history = this.props.history;
    }

    render() {
        return (
            <div className="App">
                <PrivateRoute exact path="/" component={home}/>
                <Route exact path='/login' component={Login}/>
                <PrivateRoute exact path='/search' component={Search}/>
                <PrivateRoute exact path='/message' component={Message}/>
                <PrivateRoute exact path='/chatFeed' component={ChatFeed}/>
                <Route exact path='/otpRequest' component={OtpRequest}/>
                <Route exact path='/otpVerify' component={OtpVerify}/>
                <Route exact path='/resetPassword' component={ChangePassword}/>
                <Route exact path='/otpRequestRegistration' component={otpRequestRegistration}/>
                <Route exact path='/otpVerifyRegistration' component={otpVerifyRegistration}/>
                <Route exact path='/registration' component={Registration}/>
                <Route exact path='/imageCrop' component={ImageCrop}/>
                <PrivateRoute path="/users/:userId" component={ViewProfile}/>
                <PrivateRoute path="/updateProfile" component={UpdateProfile}/>
                <PrivateRoute path="/profileQuestions" component={ProfileQuestions}/>
                <PrivateRoute path="/searchResults" component={searchResults}/>
                <PrivateRoute path="/reportUser" component={reportUser}/>
                <PrivateRoute path="/accountSettings" component={AccountSettings}/>
                <PrivateRoute path="/changeEmail" component={ChangeEmail}/>
                <PrivateRoute path="/verifyEmail" component={verifyEmail}/>
                <PrivateRoute path="/changePassword" component={changePassword}/>
                <PrivateRoute path="/viewFriendsList" component={viewFriends}/>
                <PrivateRoute path="/viewSentFriendRequests" component={sentFriendRequests}/>
                <PrivateRoute path="/pendingFriendRequests" component={pendingFriendRequests}/>
                <PrivateRoute path="/membershipPlans" component={membershipPlansList}/>
                <PrivateRoute path="/membership/:membershipId" component={membershipPlansDetails}/>
                <PrivateRoute path="/nearMe" component={nearMe}/>
                <PrivateRoute path="/nearMeSettings" component={nearMeSettings}/>
                <PrivateRoute path="/logout" component={logout}/>
                <PrivateRoute path="/advanceSearch" component={advanceSearch}/>
                <PrivateRoute path="/localization" component={Localization}/>

                {/*<Redirect from="*" to="/"/>*/}
            </div>
        );
    }
}

export default App;

export let history = App.history;



