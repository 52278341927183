import {registrationConstants} from "../constants/registrationConstants";
import {resetPasswordConstants} from "../constants/resetPasswordConstants";

const initialState = {
    otpRequest: {
        sendingOTP: false,
        invalidData: false,
        internalError: false,
        responseData: {},
        errorMessage: ''
    },
    otpVerify: {
        verifyingOTP: false,
        invalidData: false,
        internalError: false,
        responseData: {},
        errorMessage: ''
    },
    userRegister: {
        registeringUser: false,
        existsUserName: false,
        invalidData: false,
        internalError: false,
        responseData: {},
        errorMessage: ''
    },
};

const otpRequestRegisterReducer = (state = initialState.otpRequest, action) => {
    switch (action.type) {
        case registrationConstants.OTP_REQUEST:
            return {...initialState.otpRequest, sendingOTP: true};
        case registrationConstants.OTP_SUCCESS:
            return {...initialState.otpRequest, responseData: action.responseData};
        case registrationConstants.OTP_FAILURE:
            switch (action.errorCode) {
                case 400:
                case 409:
                    return {
                        ...initialState.otpRequest,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.otpRequest,
                        internalError: true
                    }
            }
        case  resetPasswordConstants.CLEAR:
        default:
            return initialState.otpRequest;
    }
};

const otpVerifyRegisterReducer = (state = initialState.otpVerify, action) => {
    switch (action.type) {
        case registrationConstants.OTP_VERIFICATION_REQUEST:
            return {...initialState.otpVerify, verifyingOTP: true};
        case registrationConstants.OTP_VERIFICATION_SUCCESS:
            return {...initialState.otpVerify, responseData: action.responseData};
        case registrationConstants.OTP_VERIFICATION_FAILURE:
            switch (action.errorCode) {
                case 400:
                    return {
                        ...initialState.otpVerify,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.otpVerify,
                        internalError: true
                    }
            }
        default:
            return state;
    }
};


const userRegisterReducer = (state = initialState.userRegister, action) => {
    switch (action.type) {
        case registrationConstants.REGISTRATION_REQUEST:
            return {...initialState.userRegister, registeringUser: true};
        case registrationConstants.REGISTRATION_SUCCESS:
            return {...initialState.userRegister, responseData: action.responseData};
        case registrationConstants.REGISTRATION_FAILURE:
            switch (action.errorCode) {
                case 400:
                case 401:
                    return {
                        ...initialState.userRegister,
                        invalidData: true,
                        errorMessage: action.errorMessage
                    };
                case 500:
                    return {
                        ...initialState.userRegister,
                        existsUserName: true,
                        errorMessage: action.errorMessage
                    };
                default:
                    return {
                        ...initialState.userRegister,
                        internalError: true
                    }
            }
        default:
            return state;
    }
};


export {otpRequestRegisterReducer, otpVerifyRegisterReducer, userRegisterReducer}
