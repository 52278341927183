import {utils} from "../helper/utils";
import {helperConstants} from "../constants/helperConstants";

export const helperActions = {loadCountryStateCityList, loadStateCityList, loadCityList};

function loadCountryStateCityList(countryIndex, stateIndex) {
    return dispatch => {
        utils.loadCountryStateCityList(countryIndex, stateIndex).then(({countryList, stateList, cityList}) => {
            dispatch(success(countryList, stateList, cityList))
        });
    };

    function success(countryList, stateList, cityList) {
        return {
            type: helperConstants.GET_COUNTRY_LIST,
            countryList: countryList,
            stateList: stateList,
            cityList: cityList
        };
    }
}

function loadStateCityList(countryIndex) {
    return dispatch => {
        utils.loadStateCityList(countryIndex).then(({stateList, cityList}) => {
            dispatch(success(stateList, cityList))
        });
    };

    function success(stateList, cityList) {
        return {
            type: helperConstants.GET_STATE_LIST,
            stateList: stateList,
            cityList: cityList
        };
    }
}

function loadCityList(countryIndex, stateIndex) {
    return dispatch => {
        utils.loadCityList(countryIndex, stateIndex).then(({cityList}) => {
            dispatch(success(cityList))
        });
    };

    function success(cityList) {
        return {
            type: helperConstants.GET_CITY_LIST,
            cityList: cityList
        };
    }
}
