import React, {Component} from 'react';

class ChatBubble extends Component {

    constructor(props) {
        super(props);

        this.state = {visible: false};
    }

    getDate = (prevMessage, message) => {

        const getJsonDate = (date) => {
            const dateTime = date.split(' ');

            const dateChunk = dateTime[0].split('-');
            const timeChunk = dateTime[1].split(':');

            return {
                'month': dateChunk[0],
                'day': dateChunk[1],
                'year': dateChunk[2],
                'hour': timeChunk[0],
                'minute': timeChunk[1],
                'second': timeChunk[2]
            }
        };

        const nowDate = new Date().toString().split(' ');

        const date = getJsonDate(message.updated_at);
        const now = getJsonDate(`${nowDate[1]}-${nowDate[2]}-${nowDate[3]} ${nowDate[4]}`);

        if (!prevMessage) {
            const date = getJsonDate(message.updated_at);

            if (now['year'] === date['year']) {
                return <label className="date-header">
                    {date['month']} {date['day']}
                </label>;
            } else {
                return <label className="date-header">
                    {date['month']} {date['day']}, {date['year']}
                </label>;
            }
        }

        const pDate = getJsonDate(prevMessage.updated_at);

        if (pDate['year'] === date['year'] && now['year'] === date['year']) {
            if (pDate['month'] === date['month'] && pDate['day'] === date['day']) {
                return <label/>;
            } else {
                return <label className="date-header">
                    {date['month']} {date['day']}
                </label>;
            }
        } else {
            return <label className="date-header">
                {date['month']} {date['day']}, {date['year']}
            </label>;
        }
    };

    toggleDateVisibility = () => {
        this.setState(prevState => {
            return {visible: !prevState.visible};
        });
    };

    getStatus = (message, nextMessage) => {
        if (!nextMessage || message.status !== nextMessage.status) {
            return <label className="message-sent">{message.status}</label>;
        } else {
            return <div/>
        }
    };

    render() {
        const {prevMessage, message, nextMessage, other} = this.props;
        const {visible} = this.state;

        return (
            <div className="chat-div" onClick={this.toggleDateVisibility}>
                <div className={other ? 'chat-bubble-right' : 'chat-bubble-left'}>
                    {this.getDate(prevMessage, message)}
                    {visible && <div className='sent-date'>
                        {message.updated_at}
                    </div>}
                    <div className="chat-bubble">
                        <label>{message.chat_text}</label>
                    </div>
                    <br/>
                    {this.getStatus(message, nextMessage)}
                    {message.status === 'failed' &&
                    <button onClick={(e) => {
                        e.stopPropagation();
                        this.props.resendMessage(message.chat_text, message.device_message_id)
                    }}>Retry</button>}
                </div>
            </div>
        );
    }
}

export default ChatBubble;
