import React, {Component} from 'react';
import Tab from "../tab/tab";
import UserStack from "./userStack";
import UserTrending from "./userTrending";
import '../../css/style.css'
import {apiService} from "../../services/apiService";
import {userConstants} from "../../constants/userConstants";
import {history} from "../app/App";
import {messaging} from "../../helper/init-fcm";
import firebase from 'firebase/app';
import 'firebase/database';
import {friendActions} from "../../actions/friendActions";
import {connect} from "react-redux";
import SideDrawer from '../SideDrawer/SideDrawer'
import {messageActions} from "../../actions/messageActions";
import Loader from "../Loader/Loader";
import {default as LOCALE} from "../../localization"
import {utils} from "../../helper/utils";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: false,
            sideDrawerOpen: false,
        };

        if (firebase.messaging.isSupported()) {
            this.requestNotification();
        }
    }

    requestNotification = () => {
        Notification.requestPermission()
            .then(async function () {
                const token = await messaging.getToken();
                console.log("requestNotification FCM_TOKEN=", token);
                localStorage.setItem(userConstants.FCM_TOKEN, token);
            })
            .catch(function (err) {
                console.log("Unable to get permission to notify.", err);
            });

        navigator.serviceWorker.addEventListener("message", (message) => console.log(message));
    };


    drawerToggleClickHandler = () => {
        this.setState(prevState => {
            return {sideDrawerOpen: !prevState.sideDrawerOpen}
        })
    };

    dismissSideDrawer = () => {
        this.setState({sideDrawerOpen: false})
    };


    async checkUpgrade() {
        //upgrade check
        const shouldUpgrade = await utils.shouldUpgrade();

        console.log('home.shouldUpgrade=', shouldUpgrade);

        if (shouldUpgrade) {
            console.log('home.shouldUpgrade.deleting all caches');
            await caches.keys().then(function (cacheNames) {
                console.log('home.shouldUpgrade caches.keys()');
                cacheNames.forEach((cacheName) => {
                    caches.delete(cacheName);
                });
                console.log('home.shouldUpgrade reload()');
                window.location.reload();
            });
        }
        console.log('home.shouldUpgrade outside');
    }

    async componentDidMount() {
        await this.checkUpgrade();

        apiService.getMyProfile().then(response => {
            //case when profile is created
            console.log('getMyProfile response=', response);

            const {id} = response.data;

            localStorage.setItem(userConstants.USER_ID, id);

            this.setState({loading: false});

            console.log('getMyProfile response=', response);

            // this.props.listenFriendRequest();
            this.props.listenForNewMessages(3000);
            if (response.data.logged_in_from === 'facebook' && response.data.edited === '0') {
                this.setState({loading: false});

                history.push('/updateProfile', {createNew: false, initialData: response.data, includesLogout: true});

                return;
            }


        }).catch(error => {
            //case when either profile is not created or there is some error

            console.log('getMyProfile error=', error);

            if (error && error.response && 404 === error.response.status) {
                this.setState({loading: false});

                history.push('/updateProfile', {createNew: true, includesLogout: true});

                return;
            }

            // localStorage.removeItem(userConstants.ACCESS_TOKEN);
            this.setState({error: true, loading: false});
        });

        if (firebase.messaging.isSupported()) {
            messaging.onMessage((payload) => {
                console.log('Message received. ', payload.data);
                this.setState({message: payload.data.message});
            });
        }
    }

    render() {
        LOCALE.setLanguage(localStorage.getItem(userConstants.LANGUAGE_DEFAULT));
        const {loading, error, sideDrawerOpen} = this.state;

        return (
            <div className='home-page-wrapper'>
                <div className='home-page'>
                    {error && <div>{LOCALE.internal_error}</div>}
                    {loading ? <Loader/> :
                        <div className="homepage-content">
                            {/*<div className="home-notification">*/}

                            {/*    <img src={process.env.PUBLIC_URL + '/assets/icons/notification.png'}*/}
                            {/*         alt="notification-icon"/>*/}
                            {/*    <div className="notification-number"><FriendRequestNotification/></div>*/}
                            {/*</div>*/}
                            {error && <div>{LOCALE.internal_error}</div>}
                            <h2 className="home-h2">{LOCALE.trending_users}</h2>
                            <div className="trending-user-space"><UserTrending/></div>
                            <br/>
                            <h2>{LOCALE.meet_this_user}</h2>
                            <UserStack/>
                        </div>}

                    <div className="homepage-content2">
                        <SideDrawer show={sideDrawerOpen}
                                    onOutsideClick={this.dismissSideDrawer}/>
                    </div>

                    <div className='tab-footer'>
                        <Tab drawerClickHandler={this.drawerToggleClickHandler}/>
                    </div>

                </div>
            </div>
        );
    }
}

const actionCreators = {
    listenFriendRequest: friendActions.listenFriendRequest,
    listenForNewMessages: messageActions.listenForNewMessages,
};

export default connect(null, actionCreators)(Home);

